import { AnimatePresence, motion } from 'framer-motion'
import CountUp from 'react-countup'
import { BigNumber } from 'ethers'

import { SVGS, PNGS } from '@/assets'
import useCurrencyStore from '@/store/useCurrencyStore'
import {
  BlockHeader,
  CollapsedWrapper,
  BalanceWrapper,
  OverviewLabel,
  OverviewRow,
  CollapsedBalanceWrapper,
  OverviewPanelWrapper,
  StatsOverviewRow,
  OverviewButtonWrapper,
  GameModeButton,
  PanelContainer,
} from './style'
import useZeroDev from '@/hooks/useZeroDev'
import { GameHistory } from '@/components/GameHistory'
import { ButtonEnum } from '../../Button'
import { QuickPlayButton, QuickPlaySetupButton } from '../../Button/QuickPlayButton'
import { useLocalStorage } from 'usehooks-ts'
import { useWeb3 } from '@/hooks/useWeb3'
import useUserDataStore from '@/store/useUserDataStore'
import { multiplyBigNumberWithFixedPointNumber } from '@/lib/crypto'
import { addAppNoti } from '@/store/useNotiStore'
import { useIsAuthed } from '@/hooks/useIsAuthed'
import { useWeb3Modal } from '@web3modal/ethers5/react'
import useAuthStore from '@/store/useAuthStore'
import { useShallow } from 'zustand/react/shallow'
import useAuth from '@/hooks/useAuth'

export const OverviewPanel = () => {
  const [isShowPanel, setShowPanel] = useLocalStorage<boolean>('showBalancePanel', true)
  const balances = useCurrencyStore(state => state.balances)
  const selectedCurrencyAmount = balances.usdc
  const { activateAA, deactivateAA, isUsingAA, isActivatingAA, hasSetupBefore } = useZeroDev()
  const { isActivating, account: address, isActive } = useWeb3()
  const {
    accWinningAmount,
    accLosingAmount,
    winThresholdForPoint,
    loseThresholdForPoint,
    bonusLevelPoints,
    levelMultiplier,
    cachedAssistPoints,
  } = useUserDataStore()
  const isAuthed = useIsAuthed()
  const { authenticate, isVerifying, isAuthing, authToken } = useAuth()
  const { open } = useWeb3Modal()

  const overviewActionBtnElem = useMemo(() => {
    if (!isAuthed) {
      return (
        <QuickPlaySetupButton
          onClick={async () => {
            try {
              if (!authToken && isActive) {
                return authenticate()
              }

              return open()
            } catch (err) {
              console.error(err)
            }
          }}
          disabled={isActivating || isVerifying || isAuthing}
        >
          <span>SETUP QUICKPLAY</span>
        </QuickPlaySetupButton>
      )
    }

    if (hasSetupBefore) {
      return (
        <>
          <QuickPlayButton
            onClick={async () => {
              try {
                if (isActivatingAA || isUsingAA) return
                await activateAA()
                if (!hasSetupBefore) window.location.reload()
                addAppNoti({
                  msg: `Quickplay enabled`,
                  type: 'success',
                })
              } catch (err) {
                addAppNoti({
                  msg: `Failed: enabling quickplay`,
                  type: 'error',
                })
                throw new Error(`Error enabling quickplay`)
              }
            }}
            disabled={isActivatingAA}
            $isActive={isUsingAA}
          >
            <span>QUICKPLAY</span>
          </QuickPlayButton>
          <GameModeButton
            onClick={() => {
              if (!isUsingAA) return
              try {
                deactivateAA()
                addAppNoti({
                  msg: `Standard enabled`,
                  type: 'success',
                })
              } catch (err) {
                addAppNoti({
                  msg: `Failed: enabling standard`,
                  type: 'error',
                })
                throw new Error(`Error enabling standard`)
              }
            }}
            disabled={false}
            isLoading={false}
            buttonType={ButtonEnum.PRIMARY_1}
            $isActive={!isUsingAA}
          >
            <span>STANDARD</span>
          </GameModeButton>
        </>
      )
    }

    return (
      <QuickPlaySetupButton
        onClick={async () => {
          try {
            await activateAA()
            window.location.reload()
          } catch (err) {
            console.error(err)
          }
        }}
        disabled={isActivatingAA || !address}
      >
        <span>SETUP QUICKPLAY</span>
      </QuickPlaySetupButton>
    )
  }, [
    isActivating,
    isActivating,
    isUsingAA,
    authenticate,
    authToken,
    isActive,
    isAuthed,
    address,
    isActivatingAA,
  ])

  return (
    <AnimatePresence>
      <PanelContainer>
        <OverviewPanelWrapper className='solid-color'>
          <BlockHeader $isShowPanel={isShowPanel} onClick={() => setShowPanel(!isShowPanel)}>
            <motion.div
              key={String(isShowPanel)}
              transition={{
                duration: 0.25,
                ease: 'easeIn',
              }}
              initial={{
                opacity: 0,
                height: 0,
              }}
              animate={{
                opacity: 1,
                height: 'auto',
              }}
              exit={{
                opacity: 0,
                height: 0,
              }}
            >
              {isShowPanel ?
                <>
                  <img src={SVGS.rewardIcon} alt='wallet-icon' />
                  <p>BALANCE</p>
                </>
              : <CollapsedWrapper>
                  <CollapsedBalanceWrapper>
                    <img src={SVGS.usdcIcon} alt='fare' />
                    <CountUp
                      end={Number(selectedCurrencyAmount)}
                      decimals={2}
                      duration={2}
                      separator={','}
                      suffix={' USDC'}
                      preserveValue
                    />
                    {/* <span style={{ color: COLORS.error, marginLeft: SPACING.xs }}>(-2.53%)</span> */}
                  </CollapsedBalanceWrapper>
                </CollapsedWrapper>
              }
            </motion.div>
            <img
              src={PNGS.chevronIcon}
              style={{
                transform: `rotate(${isShowPanel ? 180 : 0}deg)`,
                transition: '.2s ease-in-out',
              }}
            />
          </BlockHeader>
          {isShowPanel ?
            <motion.div
              key={String(isShowPanel)}
              transition={{
                duration: 0.25,
                ease: 'easeIn',
              }}
              initial={{
                opacity: 0,
                height: 0,
              }}
              animate={{
                opacity: 1,
                height: 'auto',
              }}
              exit={{
                opacity: 0,
                height: 0,
              }}
            >
              <BalanceWrapper>
                <>
                  <StatsOverviewRow className='left-border'>
                    <div>
                      <img src={SVGS.usdcIcon} alt='usdc' />
                      <CountUp
                        end={Number(selectedCurrencyAmount)}
                        decimals={2}
                        duration={2}
                        separator={','}
                        suffix={' USDC'}
                        preserveValue
                      />
                    </div>
                    <div>
                      <img src={SVGS.deathmatchIcon} alt='usdc' />
                      <CountUp
                        end={
                          multiplyBigNumberWithFixedPointNumber(
                            BigNumber.from(accWinningAmount || '0'),
                            String(levelMultiplier || 1)
                          )
                            .div(winThresholdForPoint || 1)
                            .toNumber() +
                            multiplyBigNumberWithFixedPointNumber(
                              BigNumber.from(accLosingAmount || '0'),
                              String(levelMultiplier || 1)
                            )
                              .div(loseThresholdForPoint || 1)
                              .toNumber() +
                            // Add Assists
                            (cachedAssistPoints || 0) +
                            // Add bonus level points
                            (bonusLevelPoints || 0) || 0
                        }
                        decimals={0}
                        duration={2}
                        separator={','}
                        suffix={' POINTS'}
                        preserveValue
                      />
                    </div>
                  </StatsOverviewRow>

                  <OverviewLabel>
                    <img src={SVGS.slotsIcon} alt='history' />
                    <span>HISTORY</span>
                  </OverviewLabel>
                  <OverviewRow className='left-border-large'>
                    <GameHistory />
                  </OverviewRow>
                </>

                <OverviewButtonWrapper>{overviewActionBtnElem}</OverviewButtonWrapper>
              </BalanceWrapper>
            </motion.div>
          : null}
        </OverviewPanelWrapper>
      </PanelContainer>
    </AnimatePresence>
  )
}
