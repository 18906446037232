import { PNGS, SVGS } from '@/assets'
import { BREAKPOINTS, floatingContainer } from '@/design'
import { gameNameToSVGMap, USDC_DECIMALS } from '@/lib/crypto'
import numeral from 'numeral'
import { AnimatePresence, type Variants, motion } from 'framer-motion'
import { ClickToCopy } from '../ClickToCopy'
import useUserDataStore from '@/store/useUserDataStore'
import { useWeb3 } from '@/hooks/useWeb3'
import { getUserImageToRender, getUsernameToRender } from '@/utils/userDisplay'
import { fsocket } from '@/lib/fsocket'
import { utils } from 'ethers'

export const SLiveEntriesWrapper = styled(motion.div)`
  width: 100%;
  height: 100%;
  border-radius: 6px;
  flex-grow: 1;
  box-sizing: border-box;
  grid-column: 1 / span 5;
  user-select: none;
  ${floatingContainer};
  font-family: GohuUppercase, monospace;
  overflow: scroll;

  @media only screen and (max-width: ${BREAKPOINTS.lg}px) {
    grid-column: 1 / span 5;
  }
`

export const SBetLink = styled.a`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  padding-top: 7px;
  padding-bottom: 7px;
  width: 100%;

  img {
    filter: invert();
    width: 10px;
    height: 10px;
  }
`

const SLEHeaderRow = styled(motion.div)`
  user-select: none;
  display: flex;
  align-items: center;
  height: 32px;
  border-bottom: 1px solid #222;
  background: transparent;
  position: sticky;
  z-index: 9;
  width: 100%;
  background: black;
  top: 0;
  box-sizing: border-box;
  padding-left: 16px;
  padding-right: 16px;
  > div {
    color: #aaa;
    font-size: 12px;
    line-height: 9px;
    padding-top: 1px;
    &.le-center {
      text-align: center;
    }
    &:nth-child(1) {
      width: 29%;
    }
    &:nth-child(2) {
      width: 22%;
    }
    &:nth-child(3) {
      width: 21%;
    }
    &:nth-child(4) {
      width: 21%;
    }
    &:nth-child(5) {
      width: 7%;
    }
  }
`

const SLEData = styled.div`
  color: #aaa;
  display: flex;
  align-items: center;
  height: 24px;
  background: transparent;
  .mr-left {
    margin-left: 8px;
  }
  &.le-center {
    justify-content: center;
  }
  > span {
    &.le-win {
      color: #01ff00;
    }
    font-size: 12px;
    line-height: 9px;
    padding-top: 1px;
  }
`

const SLEDataRow = styled(motion.div)`
  display: flex;
  padding: 6px 16px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.08);
  width: 100%;
  box-sizing: border-box;
  ${SLEData} {
    display: flex;
    align-items: center;
    font-family: GohuUppercase, monospace;
    line-height: 9px;
    padding-top: 1px;
    &:nth-child(1) {
      width: 29%;
    }
    &:nth-child(2) {
      width: 22%;
    }
    &:nth-child(3) {
      width: 21%;
    }
    &:nth-child(4) {
      width: 21%;
    }
    &:nth-child(5) {
      width: 7%;
    }
  }
`

const leRowVariants: Variants = {
  initial: {
    opacity: 0,
    x: 20,
  },
  animate: {
    opacity: 1,
    x: 0,
  },
  exit: {
    opacity: 0,
    x: 20,
  },
}

export const RefactorLiveEntries = () => {
  const { liveEntries } = fsocket.liveEntry.useState()
  const { account } = useWeb3()
  const {
    username,
    avatarSeedAddress,
    isUsingTwitterToDisplay,
    twitterUsername,
    twitterImage,
    inProgressEntries,
  } = useUserDataStore()

  const memoInProgressEntries = useMemo(() => {
    const inProgressEntryElems: React.ReactNode[] = []

    console.log('qk: inprogress entries: ', inProgressEntries)
    if (!account) return
    if (inProgressEntries && inProgressEntries.length) {
      inProgressEntries.forEach(inProgressEntry => {
        const resultEntry = '?'
        // const shortAddr = liveEntry.fundOwnerAddress.substring(0, 10)
        const entryAmount = numeral(
          Number(utils.formatUnits(inProgressEntry.multiplier || 0, USDC_DECIMALS))
        ).format('0,0')

        const imageToRender = getUserImageToRender(
          account,
          isUsingTwitterToDisplay,
          avatarSeedAddress,
          twitterImage,
          {},
          { borderRadius: 100, maxHeight: 20 },
          {
            height: '16px',
            width: '16px',
          },
          {
            height: '16px',
            width: '16px',
          }
        )

        const usernameToRender = getUsernameToRender(
          account.toLowerCase(),
          isUsingTwitterToDisplay,
          username,
          twitterUsername,
          {
            transition: 'all ease-in-out 0.16',
            // color: '#aaa',
            fontSize: '12px',
            lineHeight: '9px',
            paddingTop: '1px',
          }
        )

        const gameImageToRender = () => {
          if (
            inProgressEntry.gameName &&
            (gameNameToSVGMap as any)[inProgressEntry.gameName as string]
          ) {
            return (
              <img
                src={
                  (gameNameToSVGMap as any)[inProgressEntry.gameName as string] ||
                  SVGS.questionMarkIcon
                }
                width={16}
              />
            )
          }
          return <img src={SVGS.questionMarkIcon} width={16} />
        }

        inProgressEntryElems.push(
          <SLEDataRow
            key={inProgressEntry.txHash}
            layoutId={inProgressEntry.txHash}
            initial={{ opacity: 0, y: -30 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -30 }}
            transition={{ duration: 0.3 }}
          >
            <SLEData>
              {/* {liveEntry.fundOwnerIsUsingTwitterToDisplay ?
              <img
                src={liveEntry.fundOwnerTwitterImage}
                style={{ borderRadius: 100, maxHeight: 20 }} // @TODO: Do better css, I just made it so that it works for now
              ></img>
            : <SAvatar size='sm' seed={liveEntry.fundOwnerAvatarSeedAddress} />} */}
              {imageToRender}
              <ClickToCopy className='mr-left' copyText={inProgressEntry.fundOwnerAddress}>
                {usernameToRender}
              </ClickToCopy>
            </SLEData>
            <SLEData>
              <img src={SVGS.usdcIcon} width={16} />
              <span className='mr-left'>{entryAmount}</span>
            </SLEData>
            <SLEData className='le-center'>
              <span className={'le-loss'}>{resultEntry}</span>
            </SLEData>
            {/* TODO: With Q and K we cant just identify games from events */}
            <SLEData className='le-center'>
              {gameImageToRender()}
              {/* <img src={contractAddressToSVGMap[liveEntry.contractAddress]} width={16} /> */}
            </SLEData>
            <SLEData className='le-center'>
              <SBetLink
                href={`https://sepolia.arbiscan.io/tx/${inProgressEntry.txHash}`}
                target='_blank'
              >
                <img src={PNGS.linkIcon} alt='tx-link' />
              </SBetLink>
            </SLEData>
          </SLEDataRow>
        )
      })
    }

    return inProgressEntryElems
  }, [
    inProgressEntries,
    account,
    isUsingTwitterToDisplay,
    twitterImage,
    twitterUsername,
    username,
    avatarSeedAddress,
  ])

  const memoLiveEntries = useMemo(() => {
    const liveEntryElems: React.ReactNode[] = []

    console.log('qk: liveEntries: ', liveEntries)
    liveEntries.forEach(liveEntry => {
      if (!liveEntry.deltaAmount) return
      // if (
      //   !tokenAddressToFormatter[liveEntry.tokenAddress] ||
      //   !contractAddressToSVGMap[liveEntry.contractAddress]
      // ) {
      //   return
      // }

      let resultEntry = numeral(
        utils.formatUnits(liveEntry.deltaAmount || 0, USDC_DECIMALS)
      ).format('0,0')
      let isWin = false
      // console.log('qk: liveEntry.deltaAmount', liveEntry.deltaAmount)
      // console.log('qk: Number(liveEntry.deltaAmount)', Number(liveEntry.deltaAmount))
      if (Number(liveEntry.deltaAmount) > 0) {
        resultEntry = '+' + resultEntry
        isWin = true
      }

      // const shortAddr = liveEntry.fundOwnerAddress.substring(0, 10)
      const entryAmount = numeral(
        Number(utils.formatUnits(liveEntry.multiplier || 0, USDC_DECIMALS))
      ).format('0,0')

      const imageToRender = getUserImageToRender(
        liveEntry.fundOwnerAddress,
        liveEntry.fundOwnerIsUsingTwitterToDisplay,
        liveEntry.fundOwnerAvatarSeedAddress,
        liveEntry.fundOwnerTwitterImage,
        {},
        { borderRadius: 100, maxHeight: 20 },
        {
          height: '16px',
          width: '16px',
        },
        {
          height: '16px',
          width: '16px',
        }
      )

      const usernameToRender = getUsernameToRender(
        liveEntry.fundOwnerAddress,
        liveEntry.fundOwnerIsUsingTwitterToDisplay,
        liveEntry.fundOwnerUsername,
        liveEntry.fundOwnerTwitterUsername,
        {
          transition: 'all ease-in-out 0.16',
          // color: '#aaa',
          fontSize: '12px',
          lineHeight: '9px',
          paddingTop: '1px',
        }
      )

      const gameImageToRender = () => {
        if (liveEntry.gameName && (gameNameToSVGMap as any)[liveEntry.gameName as string]) {
          return (
            <img
              src={(gameNameToSVGMap as any)[liveEntry.gameName as string] || SVGS.questionMarkIcon}
              width={16}
            />
          )
        }
        return <img src={SVGS.questionMarkIcon} width={16} />
      }

      liveEntryElems.push(
        <SLEDataRow
          key={liveEntry.resolvementTxHash}
          layoutId={liveEntry.resolvementTxHash}
          initial={{ opacity: 0, y: -30 }}
          animate={{ opacity: 1, y: 0 }}
          exit={{ opacity: 0, y: -30 }}
          transition={{ duration: 0.3 }}
        >
          <SLEData>
            {/* {liveEntry.fundOwnerIsUsingTwitterToDisplay ?
              <img
                src={liveEntry.fundOwnerTwitterImage}
                style={{ borderRadius: 100, maxHeight: 20 }} // @TODO: Do better css, I just made it so that it works for now
              ></img>
            : <SAvatar size='sm' seed={liveEntry.fundOwnerAvatarSeedAddress} />} */}
            {imageToRender}
            <ClickToCopy className='mr-left' copyText={liveEntry.fundOwnerAddress}>
              {usernameToRender}
            </ClickToCopy>
          </SLEData>
          <SLEData>
            <img src={SVGS.usdcIcon} width={16} />
            <span className='mr-left'>{entryAmount}</span>
          </SLEData>
          <SLEData className='le-center'>
            <span className={isWin ? 'le-win' : 'le-loss'}>{resultEntry}</span>
          </SLEData>
          {/* TODO: With Q and K we cant just identify games from events */}
          <SLEData className='le-center'>
            {gameImageToRender()}
            {/* <img src={contractAddressToSVGMap[liveEntry.contractAddress]} width={16} /> */}
          </SLEData>
          <SLEData className='le-center'>
            <SBetLink
              href={`https://sepolia.arbiscan.io/tx/${liveEntry.resolvementTxHash}`}
              target='_blank'
            >
              <img src={PNGS.linkIcon} alt='tx-link' />
            </SBetLink>
          </SLEData>
        </SLEDataRow>
      )
    })

    return liveEntryElems
  }, [
    liveEntries,
    account,
    isUsingTwitterToDisplay,
    twitterImage,
    twitterUsername,
    username,
    avatarSeedAddress,
  ])

  return (
    <AnimatePresence mode='popLayout' initial={false}>
      <SLiveEntriesWrapper layoutScroll>
        <SLEHeaderRow key={'header'} variants={leRowVariants} layout layoutId={'header'}>
          <div>USER</div>
          <div>ENTRY</div>
          <div className='le-center'>RESULT</div>
          <div className='le-center'>GAME</div>
          <div className='le-center'>TX</div>
        </SLEHeaderRow>
        {memoInProgressEntries}
        {memoLiveEntries}
      </SLiveEntriesWrapper>
    </AnimatePresence>
  )
}
