import { InviteCodeModalWrapper } from './style'

import { BaseModal } from '../Modal'
import useAuthStore from '@/store/useAuthStore'
import { Input } from '../Input'
import { Button, ButtonEnum } from '../Button'
import { inviteCodesApi } from '@/lib/http/inviteCodes'
import { BORDER_COLORS, COLORS, FARE_COLORS } from '@/design'
import { useQuery } from '@/hooks/useQuery'
import { FormEvent } from 'react'

interface IInviteCodeModal {
  isCloseable?: boolean
  onSuccess?: () => void
}

const SPermaInviteCodeWrapper = styled.div`
  height: fit-content;
  margin: auto;
  padding: 0;
  border: 1px solid ${BORDER_COLORS.one};
  backdrop-filter: blur(6px);
  background-color: rgba(0, 0, 0, 0.72);
  border-radius: 6px;
  position: relative;
  > span {
    position: absolute;
    top: 12px;
    left: 12px;
  }
`

const SPermaInviteCodeModal = styled.form`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: center;
  padding: 24px;
  padding-top: 36px;
  padding-left: 12px;
  padding-right: 12px;
  padding-bottom: 12px;
  width: 300px;
  height: fit-content;
`

export const PermaInviteCodeModal = ({
  isCloseable = true,
  onSuccess = () => {},
}: IInviteCodeModal) => {
  const {
    // setIsShowingInviteCodeModal,
    authPublicAddress,
    // isShowingInviteCodeModal,
    // hasEnteredInviteCode,
  } = useAuthStore(state => ({
    authPublicAddress: state.authPublicAddress,
    // setIsShowingInviteCodeModal: state.setIsShowingInviteCodeModal,
    // isShowingInviteCodeModal: state.isShowingInviteCodeModal,
    // hasEnteredInviteCode: state.hasEnteredInviteCode,
  }))
  const query = useQuery()
  const [inviteCode, setInviteCode] = useState(query.get('inviteCode') || '')
  const [isValidating, setIsValidating] = useState(false)
  const [errorMsg, setErrorMsg] = useState('')
  const [outOfRequests, setIsOutOfRequests] = useState(false)

  const tryInviteCode = useCallback(
    async (event: FormEvent<HTMLFormElement>) => {
      event.preventDefault()
      try {
        setIsValidating(true)
        setErrorMsg('')
        const resp = await inviteCodesApi.tryInviteCode(inviteCode, authPublicAddress)
        if (!resp.isSuccessful) {
          if (resp.hoursLeft) setIsOutOfRequests(true)
          setErrorMsg(resp.reason || 'Error with request')
          throw new Error(resp.reason || 'Error with request')
        }

        useAuthStore.setState({ hasEnteredInviteCode: true })
        // setIsShowingInviteCodeModal(false)
        onSuccess()
      } catch (err) {
        throw new Error(err as any)
      } finally {
        setIsValidating(false)
      }
    },
    // [authPublicAddress, inviteCode, setIsShowingInviteCodeModal]
    [authPublicAddress, inviteCode]
  )

  return (
    <SPermaInviteCodeWrapper>
      <span>ENTER INVITE CODE:</span>
      <SPermaInviteCodeModal onSubmit={tryInviteCode}>
        <span
          style={{
            color: FARE_COLORS.salmon,
            paddingBottom: 18,
          }}
        >
          {errorMsg}
        </span>
        <Input
          autoFocus
          placeholder={'Invite Code...'}
          value={inviteCode}
          onChange={ev => setInviteCode(ev.currentTarget.value.trim())}
        />
        <Button
          style={{ marginTop: 16 }}
          buttonType={ButtonEnum.CONNECT_WALLET}
          disabled={isValidating || !inviteCode || outOfRequests}
          isLoading={isValidating}
          type='submit'
        >
          Submit
        </Button>
      </SPermaInviteCodeModal>
    </SPermaInviteCodeWrapper>
  )
}
