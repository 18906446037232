import { BACKGROUND_COLORS, COMPONENTS, SPACING, fade } from '@/design'
import { motion } from 'framer-motion'

export const ProfileWrapper = styled(motion.div)`
  display: grid;
  grid-template-columns: 1fr;
  justify-content: center;
  grid-gap: ${SPACING.xxs}px;
  transition: 0.2s all ease-in-out;
  position: absolute;
  height: calc(100vh - ${COMPONENTS.header + 1}px);
  top: ${COMPONENTS.header + 1}px;
  bottom: ${COMPONENTS.footer.main}px;
  overflow: hidden;
  z-index: 200;
  left: 0;
  right: 0;
  bottom: 0;
  background: ${BACKGROUND_COLORS.one};
  overflow-y: scroll;
  ${fade};
`

export const ImageWrapper = styled.div<{ isActive?: boolean }>`
  display: flex;
  position: relative;
  overflow: hidden;
  height: 100%;

  > img {
    width: 42px;
    height: 42px;
    margin: auto;
    padding: ${SPACING.xs}px;
  }
`
