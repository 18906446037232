import { useCoinFlipGameState } from '@/store/useGameStateStore'
import { useShallow } from 'zustand/react/shallow'
import { clamp } from '@/utils'
import { CoinCamera } from './CoinCamera'
import { CoinGround } from './CoinGround'
import { RefactorCoinModel } from './RefactorCoinModel'
import { CoinState } from './Coin'

const CoinModelMemo = memo(RefactorCoinModel)

export const CoinScene = () => {
  /* Memos */
  const {
    side,
    setSide,
    coinCount,
    type: gameState,
    results,
    submittedAmount,
  } = useCoinFlipGameState(
    useShallow(state => ({
      side: state.submittedEntry ? state.submittedEntry.side : state.entry.side,
      setSide: (side: 0 | 1) => state.setEntry({ side }),
      coinCount: state.submittedEntry ? state.submittedEntry.entryCount : state.entry.entryCount,
      type: state.type,
      results: state.results,
      submittedAmount: state.submittedEntry?.entryAmount,
    }))
  )
  const { gap, cols, zoomFactor } = useMemo(
    () => ({
      gap: {
        x: 4.25,
        y: 4.25,
      },
      cols: 5,
      zoomFactor: 0.7,
    }),
    []
  )
  const count = useMemo(() => clamp(coinCount, 1, 20), [coinCount])
  const coins = useMemo(() => {
    const coinNumbers = Array.from({ length: count }, (_, index) => index + 1)

    return coinNumbers.map((key, idx) => {
      const resultSide = results?.resultSides[idx]
      const resultDeltaAmount = results?.rewards[idx]
      const playedCount = results?.playedCount
      const hasHitLimit = results ? idx + 1 > results.playedCount : false
      let coinState: CoinState = 'idle'

      if (hasHitLimit) {
        coinState = 'isOverLimit'
      }

      return (
        <CoinModelMemo
          key={key}
          coinIdx={idx}
          gap={gap}
          cols={cols}
          side={side}
          setSide={setSide}
          gameState={gameState}
          resultSide={resultSide}
          // @NOTE: Allows the delta amount text to use submitted amount
          resultDeltaAmount={(resultDeltaAmount || 0) - (submittedAmount || 0) / count}
          hasHitLimit={hasHitLimit}
          playedCount={playedCount || 1}
        />
      )
    })
  }, [count, gap, cols, side, gameState, setSide])

  return (
    <>
      <CoinCamera coinCount={count} gap={gap} cols={cols} zoomFactor={zoomFactor} />
      <ambientLight intensity={1} />
      <directionalLight intensity={5} color={'white'} position={[-2, 3.5, -3]} />

      {coins}

      <CoinGround coinCount={count} gap={gap} cols={cols} zoomFactor={zoomFactor} />
      {/* <Preload all /> */}
    </>
  )
}
