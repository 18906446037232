import type { AddEthereumChainParameter } from '@web3-react/types'
import { CHAIN_URLS, SupportedChainId } from './web3'

export const ethChain: AddEthereumChainParameter = {
  chainId: 1,
  chainName: 'Ethereum Mainnet',
  nativeCurrency: {
    name: 'ETH',
    symbol: 'ETH',
    decimals: 18,
  },
  rpcUrls: [CHAIN_URLS[SupportedChainId.MAINNET]],
  blockExplorerUrls: ['https://etherscan.io'],
  iconUrls: [],
}

export const hardhatChain: AddEthereumChainParameter = {
  chainId: 31337,
  chainName: 'Hardhat Local',
  nativeCurrency: {
    name: 'ETH',
    symbol: 'ETH',
    decimals: 18,
  },
  rpcUrls: [CHAIN_URLS[SupportedChainId.LOCAL_HARDHAT]],
  blockExplorerUrls: [],
  iconUrls: [],
}

export const arbitrumChainParams: AddEthereumChainParameter = {
  chainId: 42161,
  chainName: 'Arbitrum One',
  nativeCurrency: {
    name: 'ETH',
    symbol: 'ETH',
    decimals: 18,
  },
  rpcUrls: [CHAIN_URLS[SupportedChainId.ARBITRUM_ONE]],
  blockExplorerUrls: ['https://explorer.arbitrum.io'],
  iconUrls: ['https://arbiscan.io/images/logo-Arbiscan.svg?v=0.0.5'],
}

export const arbitrumGoerliChainParams: AddEthereumChainParameter = {
  chainId: 421613,
  chainName: 'Arbitrum Goerli',
  nativeCurrency: {
    name: 'AGOR',
    symbol: 'AGOR',
    decimals: 18,
  },
  rpcUrls: [CHAIN_URLS[SupportedChainId.ARBITRUM_GOERLI]],
  blockExplorerUrls: ['https://goerli-rollup-explorer.arbitrum.io'],
  iconUrls: ['https://arbiscan.io/images/logo-Arbiscan.svg?v=0.0.5'],
}

export const arbitrumSepoliaChainParams: AddEthereumChainParameter = {
  chainId: 421614,
  chainName: 'Arbitrum Sepolia',
  nativeCurrency: {
    name: 'ETH',
    symbol: 'ETH',
    decimals: 18,
  },
  rpcUrls: [CHAIN_URLS[SupportedChainId.ARBITRUM_SEPOLIA]],
  blockExplorerUrls: ['https://sepolia-explorer.arbitrum.io'],
  iconUrls: ['https://docs.arbitrum.io/img/logo.svg'],
}

export const chains = {
  mainnet: ethChain,
  hardhat: hardhatChain,
  arbitrumChainParams,
  arbitrumGoerli: arbitrumGoerliChainParams,
  arbitrumSepolia: arbitrumSepoliaChainParams,
}

export function getChainParamsById(chainId: number) {
  const chainParams = Object.values(chains).filter(chainData => chainData.chainId === chainId)

  if (!chainParams.length) throw new Error('Invalid chainId')

  return chainParams
}
