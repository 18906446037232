import { useDebouncedCallback } from 'use-debounce'
import gridFrag from './shaders/grid.frag'
import gridVert from './shaders/grid.vert'
import { motion } from 'framer-motion-3d'
import { type ShaderMaterial, Color } from 'three'
import { useAnimationControls } from 'framer-motion'

interface ICoinGround {
  coinCount: number
  gap?: {
    x: number
    y: number
  }
  cols?: number
  zoomFactor?: number
}

export const CoinGround = ({
  coinCount = 1,
  gap = { x: 4.5, y: 4.25 },
  cols = 5,
  zoomFactor = 0.7,
}: ICoinGround) => {
  // const size = useThree(state => state.size)
  const shaderRef = useRef<ShaderMaterial>(null)
  const controls = useAnimationControls()

  const uniforms = useMemo(
    () => ({
      uTime: { value: 0 },
      // uGridSpacing: { value: 0.22 },
      uGridSpacing: { value: 0.22 },
      fogColor: { value: new Color(0, 0, 0) },
      fogNear: { value: 10 },
      fogFar: { value: 30 },
    }),
    []
  )

  // Callbacks
  const adjustPlanePosition = useDebouncedCallback(
    async (count = 1, gap: { x: number; y: number }, cols: number, zoom: number) => {
      // let posX = 0
      let posY = -2
      // let posZ = 10

      if (count > 1) {
        // Calculate the current row
        const currentRow = Math.floor((count - 1) / cols)
        const coinsInFirstRow = Math.min(count, cols)

        // Center the camera based on the number of coins in the first row
        // posX = ((coinsInFirstRow - 1) / 2) * gap.x

        // Calculate the Y position based on the current row
        posY = (-currentRow * gap.y) / 2

        // Calculate the Z position based on the spread of X and Y
        // const spreadX = (coinsInFirstRow - 1) * gap.x
        // const spreadY = currentRow * gap.y
        // const additionalDistance = Math.max(spreadX, spreadY)

        // Add additionalDistance and apply zoom to Z position
        // posZ += additionalDistance * zoom
      }

      controls.stop()
      await controls.start({
        // x: posX,
        y: count === 1 ? -2 : posY * 3 || -2,
        // z: posZ,
        transition: {
          x: {
            type: 'tween',
            ease: 'easeInOut',
            duration: 0.2,
          },
          y: {
            type: 'tween',
            ease: 'easeInOut',
            duration: 0.2,
          },
          z: {
            type: 'tween',
            ease: 'linear',
            duration: 0.35,
          },
        },
      })
    },
    15
  )

  // Effects
  useEffect(() => {
    adjustPlanePosition(coinCount, gap, cols, zoomFactor)
  }, [adjustPlanePosition, coinCount, gap, cols, zoomFactor])

  return (
    <motion.group animate={controls} position={[0, -2, 0]}>
      {/* Ground */}
      <mesh rotation-x={-Math.PI / 2}>
        <planeGeometry args={[100, 100]} />
        <shaderMaterial
          ref={shaderRef}
          fragmentShader={gridFrag}
          vertexShader={gridVert}
          uniforms={uniforms}
          transparent
          fog
        />
      </mesh>
    </motion.group>
  )
}
