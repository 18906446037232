import { decodeError } from 'ethers-decode-error'
import useRandomnessType from '@/hooks/useRandomnessType'
import { type ISUContractForm } from '@/store/useSUContractStore'
import {
  USDC_DECIMALS,
  currencyAddressMap,
  SUContractInterface,
  fareBankrollAddress,
  VITE_USDC_VAULT_ADDRESS,
  type GameNames,
  routeGameTypeMap,
  GameNameToQK,
  unit,
} from '@/lib/crypto'
import { wsProvider } from '@/lib/crypto/provider'
import { type GameStateReducer } from '@/lib/fare/state'
import { sendGameStoreUpdateByAddress, useDelayedLiveEntry } from '@/store/useGameStateStore'
import useSUContractStore, { initialSUContractResults } from '@/store/useSUContractStore'
import useZeroDevStore from '@/store/useZeroDevStore'
import { utils } from 'ethers'
import { useShallow } from 'zustand/react/shallow'
import { useCurrencyContracts } from './useCurrencyContracts'
import { useWeb3 } from '@/hooks/useWeb3'
import {
  isKnownErrorMessage,
  mapErrorDataToMeaningfullString,
  mapSCStringToMeaningfulString,
} from '@/lib/crypto/error'
import { addAppNoti } from '@/store/useNotiStore'
import { roundToDecimalPlaces } from '@/utils/math'
import { type UsdcVault, UsdcVault__factory } from '@/lib/crypto/typechain'
import { type IKToMs, type IMToPossibleOrderingCount } from './useGameContractListener'
import { formatEther, parseUnits } from 'ethers/lib/utils'
import useTrialStore from '@/store/useTrialStore'
import useAuth from '@/hooks/useAuth'
import { type Hex } from 'viem'
import useMaxValuesStore from '@/store/useMaxValuesStore'
// import { Hash } from 'react-router-dom'

function productRange(a: bigint, b: bigint) {
  let prd = a
  let i = a

  while (i < b) {
    i += unit
    prd *= i
    prd = prd / unit
  }
  return prd
}

function combinations(n: bigint, r: bigint) {
  if (n === r || r === 0n) {
    return unit
  } else {
    r = r < n - r ? n - r : r
    return (productRange(r + unit, n) * unit) / productRange(unit, n - r)
  }
}

function factorial(num: bigint): bigint {
  if (num === 0n) return 1n
  else return num * factorial(num - 1n)
}

function nestedForLoops(n: bigint, maxValue: bigint, callback: (indices: bigint[]) => void) {
  const indices = new Array(n).fill(0n)

  function loop(index: bigint) {
    if (index === n) {
      callback(indices)
    } else {
      for (let i = 0n; i <= maxValue; i++) {
        indices[Number(index)] = i
        loop(index + 1n)
      }
    }
  }

  loop(0n)
}

function generatePermutations(length: number, values: number[]): number[][] {
  const start = Date.now()
  const permutations: number[][] = []
  const totalPermutations = Math.pow(values.length, length) // values.length ^ length
  for (let i = 0; i < totalPermutations; i++) {
    const permutation: number[] = []
    let index = i
    for (let j = 0; j < length; j++) {
      permutation.push(values[index % values.length])
      index = Math.floor(index / values.length)
    }
    permutations.push(permutation.reverse())
  }
  const timeTaken = Date.now() - start
  console.log('qk: Total time taken to create perm: ' + timeTaken + ' milliseconds')
  return permutations
}

const fillProvidedQK = (providedQ: bigint[], providedK: bigint[]) => {
  // Check if q's sum up to 1, if not, place another entry to the end to complete it to 1 with k = 0
  const qSum = providedQ.reduce((a, b) => a + b)
  console.log('qk: qSum: ', qSum)
  if (qSum < unit) {
    // If there is an entry with k = 0, increase it's q value rather than adding a new entry
    // @TODO: Test this out
    if (providedK.includes(0n)) {
      const indexOf0 = providedK.indexOf(0n)
      providedQ[indexOf0] += unit - qSum
    } else {
      providedQ.push(unit - qSum)
      providedK.push(0n)
    }
  }
  return { q: providedQ, k: providedK }
}

const minimizeQK = (q: bigint[], k: bigint[]) => {
  if (q.length !== k.length) {
    throw new Error('Cannot minimize q and k arrays, if they are not the same length')
  }
  const minimizedQ: bigint[] = []
  const minimizedK = k.reduce((acc: bigint[], current, currentIndex) => {
    if (!acc.includes(current)) {
      // Do not consider the entry if k is 0
      if (current !== 0n) {
        acc.push(current)
        minimizedQ.push(q[currentIndex])
      }
    } else {
      const firstIndexOfSameK = acc.indexOf(current)
      minimizedQ[firstIndexOfSameK] += q[currentIndex]
    }
    return acc
  }, [])
  return { q: minimizedQ, k: minimizedK }
}

// @TODO: Consider using web workers for this part because this could get complicated pretty easily
// @TODO: What I could also do is, cache outcomes. Rather than creating all 1 million of outcomes and process one by one, create 10k outcomes and process those and afterwards create the new 10k outcomes and process them
// @TODO: For above thing to make sense (because it basically decreases the RAM needed), we probably have to add q and ks in a smart manner rather than creating q and k arrays in length of the total outcome count
// @TODO: First line is about improving CPU usage, Line 2 and 3 are about using less RAM. I dont directly know the bottle neck yet. Also, it is not worth to implement these imporvements yet because we are not sure about the decision of using this yet
// @TODO: Looks like if we play rps with 20 count, it actually runs out of memory, so that is a bottle neck for sure
// const processOutcomesAndBuildQK = (
//   outcomes: number[][],
//   providedQ: bigint[],
//   // @TODO: Make sure providedK has k for 0 and does not have any same values. Because, kToMs work on the k indexes
//   providedK: bigint[],
//   stopLossCoefficient: bigint, // should be provided in terms of c (cost per flip) so if entry cost is 100, with 5 flips, c (cost of flip) is 20. If you want to stop after one loss, you should provide 1
//   stopGainCoefficient: bigint // same as above // @TODO: maybe consider doing that conversion inside this? not sure
// ) => {
//   const start = Date.now()
//   // if stopGainCoefficient or stopLossCoefficient is 0, understand that it is not used
//   const deltaK = providedK.map(val => val - unit)
//   const q: bigint[] = Array(outcomes.length).fill(unit)
//   const k: bigint[] = []
//   const kToMs: IKToMs = {}
//   for (let outcomeCount = 0; outcomeCount < outcomes.length; outcomeCount++) {
//     const m: number[] = Array(providedK.length).fill(0)
//     const outcome = outcomes[outcomeCount]
//     let delta = 0n
//     for (
//       let flipCountToProcessForOutcome = 0;
//       flipCountToProcessForOutcome < outcome.length;
//       flipCountToProcessForOutcome++
//     ) {
//       const flipsResultIndex = outcome[flipCountToProcessForOutcome]
//       // console.log('qk: flipResultIndex: ', flipsResultIndex)
//       // @TODO: Test this
//       if (stopGainCoefficient !== 0n) {
//         if (delta >= stopGainCoefficient) {
//           // if it triggered after processing the last one, do not process the stop
//           // Continue building the q[i] before breaking
//           for (let l = flipCountToProcessForOutcome; l < outcome.length; l++) {
//             q[outcomeCount] *= providedQ[flipsResultIndex]
//             delta -= unit / 100n
//             // @TODO: Fill the m array as if it is stopped lossed (maybe, we understand that there is a stop loss or stop gain, when m values do not add up to the length of a permutation?)
//           }
//           break
//         }
//       }
//       // @TODO: Test this
//       if (stopLossCoefficient !== 0n) {
//         // @TODO: Looks like this if is wrong because these are negative values check flipping twice with amount = 100, stop loss = 15 and stop loss = 25 (I believe stop loss = 25 is not working properly)
//         // @TODO: Maybe if it is the last one, skip stop loss check? would that work? Maybe not
//         if (delta <= stopLossCoefficient) {
//           // if it triggered after processing the last one, do not process the stop
//           // Continue building the q[i] and delta before breaking
//           for (let l = flipCountToProcessForOutcome; l < outcome.length; l++) {
//             q[outcomeCount] *= providedQ[flipsResultIndex]
//             delta -= unit / 100n
//             // @TODO: Fill the m array as if it is stopped lossed (maybe, we understand that there is a stop loss or stop gain, when m values do not add up to the length of a permutation?)
//           }
//           break
//         }
//       }
//       // @TODO: Problem is that we are adding the q k for again
//       q[outcomeCount] *= providedQ[flipsResultIndex]
//       delta += deltaK[flipsResultIndex]
//       m[flipsResultIndex]++
//     }
//     // console.log('qk: m: ', m)
//     k[outcomeCount] = (delta + unit * BigInt(outcome.length)) / BigInt(outcome.length)
//     // kToMs[String(k[outcomeCount])] ?
//     //   kToMs[String(k[outcomeCount])].push(m)
//     // : (kToMs[String(k[outcomeCount])][0] = m)
//     const existingMs = kToMs[String(k[outcomeCount])]
//     if (existingMs) {
//       kToMs[String(k[outcomeCount])].push(m)
//     } else {
//       kToMs[String(k[outcomeCount])] = Array.from({ length: 1 }, () => m)
//     }
//     q[outcomeCount] /= unit ** BigInt(outcome.length)
//   }
//   console.log('qk: kToMs: ', kToMs)
//   const { q: minimizedQ, k: minimizedK } = minimizeQK(q, k)
//   const readableQ = minimizedQ.map(qi => formatEther(String(qi)))
//   const readableK = minimizedK.map(ki => formatEther(String(ki)))
//   // const readableQ = minimizedQ.reverse().map(qi => formatEther(String(qi)))
//   // const readableK = minimizedK.reverse().map(ki => formatEther(String(ki)))
//   let evSum = 0n
//   for (let i = 0; i < minimizedQ.length; i++) {
//     evSum += (minimizedQ[i] * minimizedK[i]) / unit
//   }
//   // @TODO: probably require evSum to be below 0.99 (or the value received from the smart contract)
//   // @TODO: probably check the qSum as well
//   console.log('qk: rough ', readableQ, readableK)
//   console.log('qk: rough evSum: ', formatEther(evSum))
//   const timeTaken = Date.now() - start
//   console.log('qk: Total time taken : ' + timeTaken + ' milliseconds')
//   return { q: minimizedQ, k: minimizedK, kToMs }
// }

const buildQK = (q: bigint[], k: bigint[], count: bigint) => {
  // @NOTE: Maybe, if stopLoss and stopGain are 0, consider using `updateQKForMultipleFlips()` to come up with the q and k values faster than `processOutcomesAndBuildQK` but not sure if it makes sense to keep 2 different versions for this gain of speed (probably does not)
  // const { q: filledQ, k: filledK } = fillProvidedQK(q, k)
  // @TODO: Make sure `fillProvidedQK()` return q and k so that k does not have duplicate values (not minimized tho, because we want the 0 to be there)
  // console.log(
  //   'qk: generated permutations: ',
  //   generatePermutations(
  //     Number(count),
  //     Array.from({ length: filledK.length }, (_, i) => i)
  //   )
  // )
  // return processOutcomesAndBuildQK(
  //   generatePermutations(
  //     Number(count),
  //     Array.from({ length: filledK.length }, (_, i) => i)
  //   ),
  //   filledQ,
  //   filledK,
  //   stopLoss,
  //   stopGain
  // )
  return updateQKForMultipleFlips(q, k, count)
}
// For coin flip if you play with the following counts, the evSum will not be exactly 0.99, it will be just a little less
// 7, 13, 14, 17, 18, 19, 20
// What we can technically do to resolve this is, maybe find highest K value, try increasing it's Q value by one and check if evSum exceeds 0.99, if it exceeds, try with next highest K value. If it does not, update the Q array and re call this
// }

// @NOTE: Is more efficient than `processOutcomesAndBuildQK` which processes each permutation
// @NOTE: But this does not support stop gain or stop loss, because it does not care about the order when calculating
// @NOTE: It only takes in consideration the amount of outcomes for a specific set of result
// @NOTE: So, it does not simulate and find the result as `processOutcomesAndBuildQK`, this uses a formula to quickly come up with the result
const updateQKForMultipleFlips = (providedQ: bigint[], providedK: bigint[], count: bigint) => {
  console.log('qk: building qk for multiple flips')
  if (providedK.length !== providedQ.length) {
    console.log('provided q and k lengths do not match')
  }
  const { q: filledQ, k: filledK } = fillProvidedQK(providedQ, providedK)
  const filledQKLen = BigInt(filledK.length)
  const k: bigint[] = []
  const q: bigint[] = []
  const m: bigint[] = Array(filledQKLen).fill(0n)
  const kToMs: IKToMs = {}
  const mToPossibleOrderingCount: IMToPossibleOrderingCount = {}
  console.log('qk: ', m)
  let currentIndex = 0
  nestedForLoops(filledQKLen, count, indices => {
    const mSum = indices.reduce((sum, newValue) => sum + newValue)
    if (mSum === count) {
      // console.log(`qk: ms: [${indices.join(', ')}], mSum: ${mSum}, count: ${count}`)
      // console.log('qk: indices: ', indices)
      const kVal = filledK.map((ki, i) => ki * indices[i]).reduce((a, b) => a + b) / count // here is what left hand is doing for providedK with length 3: (originalK[0] * m1 + originalK[1] * m2 + originalK[2] * m3) / count
      k[currentIndex] = kVal
      const possibleOrderingCount =
        factorial(count) / indices.map(indice => factorial(indice)).reduce((a, b) => a * b, 1n)
      console.log('qk: possibleOrderingCount: ', possibleOrderingCount)
      const qVal =
        (filledQ.map((qi, i) => qi ** indices[i]).reduce((a, b) => a * b, 1n) *
          possibleOrderingCount) /
        unit ** (count - 1n) // here is what left hand is doing for provided Q with length 3: (originalQ[0] ** m1 * originalQ[1] ** m2 * originalQ[2] ** m3 * (factorial(count) / (factorial(m1) * factorial(m2) * factorial(m3)))) / unit ** (count - 1n)
      if (qVal === 0n && kVal !== 0n) {
        throw new Error('Try decreasing count. Building Q requires more precision than we support.')
      }
      q[currentIndex] = qVal
      const existingMs = kToMs[String(k[currentIndex])]
      // console.log('qk: existing ms for currentIndex: ', existingMs)
      if (existingMs) {
        kToMs[String(k[currentIndex])].push([...indices.map(i => Number(i))])
      } else {
        kToMs[String(k[currentIndex])] = [[...indices.map(i => Number(i))]]
      }
      mToPossibleOrderingCount[[...indices.map(i => Number(i))] as any] =
        Number(possibleOrderingCount)
      console.log('qk: mToPossibleOrderingCount: ', mToPossibleOrderingCount)
      currentIndex++
    }
  })
  console.log('qk: kToMs: ', kToMs)
  const { q: minimizedQ, k: minimizedK } = minimizeQK(q, k)
  let evSum = 0n
  for (let i = 0; i < minimizedQ.length; i++) {
    evSum += (minimizedQ[i] * minimizedK[i]) / unit
  }
  // @TODO: probably require evSum to be below 0.99 (or the value received from the smart contract)
  // @TODO: probably check the qSum as well
  console.log('qk: not readable: ', minimizedQ, minimizedK)
  const readableQ = minimizedQ.map(qi => formatEther(String(qi)))
  const readableK = minimizedK.map(ki => formatEther(String(ki)))
  console.log('qk: ', readableQ, readableK)
  console.log('qk: evSum: ', formatEther(evSum))
  return { q: minimizedQ, k: minimizedK, kToMs, mToPossibleOrderingCount }
}

const packMaxValues = (
  maxEthUsdcPrice: bigint,
  maxAverageCallbackGas: bigint,
  maxAACostMultiplier: bigint
) => {
  return (maxEthUsdcPrice << 40n) + (maxAverageCallbackGas << 8n) + maxAACostMultiplier
}

export const useGameContract = (gameName: GameNames) => {
  const { provider, account } = useWeb3()
  const { pathname } = useLocation()
  const { setInProgressEntry, setIsSubmitting, setSUContractResults, setSubmittedSide } =
    useSUContractStore(state => ({
      setIsWithdrawing: state.setIsWithdrawing,
      setInProgressEntry: state.setInProgressEntry,
      setIsSubmitting: state.setIsSubmitting,
      setSubmittedSide: state.setSubmittedSide,
      setSUContractResults: state.setSUContractResults,
    }))
  const { isUsingAA, zeroDevSessionKeyProvider, zeroDevAddress } = useZeroDevStore(
    useShallow(state => ({
      isUsingAA: state.isUsingAA,
      zeroDevAddress: state.zeroDevAddress,
      zeroDevSessionKeyProvider: state.zeroDevSessionKeyProvider,
    }))
  )
  const {
    ethUsdcPriceSCValue,
    ethUsdcPriceBufferPercentage,
    averageCallbackGasSCValue,
    averageCallbackGasBufferPercentage,
    aaCostMultiplierSCValue,
    aaCostMultiplierBufferPercentage,
  } = useMaxValuesStore()
  const { setTrialData } = useTrialStore()
  const userAddress = useMemo(
    () => (isUsingAA ? zeroDevAddress : account),
    [isUsingAA, zeroDevAddress, account]
  )
  const { fetchAndSetAllowance } = useCurrencyContracts()
  const send = useMemo(() => sendGameStoreUpdateByAddress(gameName), []) as
    | GameStateReducer
    | undefined
  const { encodedRandomnessType } = useRandomnessType()
  const tokenAddress = useMemo(() => currencyAddressMap.usdc, [])

  const gameContract = useMemo(() => {
    if (!gameName || !provider) return null
    return UsdcVault__factory.connect(VITE_USDC_VAULT_ADDRESS, provider.getSigner()) as UsdcVault
  }, [gameName, provider])

  const gameContractWS = useMemo(() => {
    if (!gameName || !wsProvider) return null
    return UsdcVault__factory.connect(VITE_USDC_VAULT_ADDRESS, wsProvider) as UsdcVault
  }, [gameName, wsProvider])

  const { authApi } = useAuth()

  const submitEntry = useCallback(
    async (data: ISUContractForm) => {
      if (!gameContract || !account) return
      if (
        !(
          ethUsdcPriceSCValue &&
          ethUsdcPriceBufferPercentage &&
          averageCallbackGasSCValue &&
          averageCallbackGasBufferPercentage &&
          aaCostMultiplierSCValue &&
          aaCostMultiplierBufferPercentage
        )
      ) {
        // @TODO: Maybe have default values rather than returning?
        return
      }
      try {
        // console.log('qk given stopLoss: ', data.stopLoss, typeof data.stopLoss)
        // console.log('qk given stopGain: ', data.stopGain, typeof data.stopGain)
        // const { q, k } = buildQK(
        //   GameNameToQK[routeGameTypeMap[pathname] as GameNames].getQK(data.side).q,
        //   GameNameToQK[routeGameTypeMap[pathname] as GameNames].getQK(data.side).k,
        //   BigInt(data.numberOfEntries),
        //   // @TODO: Clean this stop loss and stop gain part. Maybe do this complicated part inside the other function
        //   (data.stopLoss as any) == 0 ?
        //     0n
        //   : -(
        //       (unit * BigInt(data.stopLoss)) /
        //       (BigInt(data.entryAmount) / BigInt(data.numberOfEntries))
        //     ),
        //   // @TODO: For some reason, stop loss's type is string and stop gain's type is number... and then stop loss was number again, wtf
        //   // @NOTE: Looks like, if user is using after resfreshing and without setting any value for stopGain or stopLoss. We will receive 0 as number, as sson as they set it to something like 1, 2, 1.3 we get that value in string
        //   data.stopGain == 0 ?
        //     0n
        //   : (unit * BigInt(data.stopGain)) /
        //       (BigInt(data.entryAmount) / BigInt(data.numberOfEntries))
        // )
        // return
        setIsSubmitting(true)

        const amountPerEntryNum = String(
          roundToDecimalPlaces(data.entryAmount / data.numberOfEntries, USDC_DECIMALS)
        )
        const amountPerCount = utils.parseUnits(amountPerEntryNum, USDC_DECIMALS)
        const stopLoss = utils.parseUnits(String(data.stopLoss || 0), USDC_DECIMALS)
        const stopGain = utils.parseUnits(String(data.stopGain || 0), USDC_DECIMALS)
        const entryCount =
          Number(data.numberOfEntries) < 1 || Number(data.numberOfEntries) > 20 ?
            1
          : Number(data.numberOfEntries)

        const submitEntryData = {
          side: data.side,
          amount: amountPerCount,
          who: account,
        }

        if (isUsingAA && zeroDevSessionKeyProvider) {
          // @TODO: AA part does not work right now
          const userOp = await zeroDevSessionKeyProvider.sendUserOperation({
            target: gameContract.address as Hex,
            data: SUContractInterface.encodeFunctionData(
              'submitEntry' as any,
              [submitEntryData] as any
            ) as Hex,
          })

          zeroDevSessionKeyProvider
            .waitForUserOperationTransaction(userOp.hash as Hex)
            .then(useDelayedLiveEntry.getState().setSubmittedTxHash)
            .catch(console.error)

          send?.({
            type: 'START',
            payload: {
              side: data.side,
              entryAmount: data.entryAmount,
              entryCount: data.numberOfEntries,
              stopLoss: data.stopLoss,
              stopGain: data.stopGain,
            },
          })

          setSUContractResults(initialSUContractResults)

          setInProgressEntry({} as any)
        } else {
          const gameQKs = GameNameToQK[routeGameTypeMap[pathname] as GameNames].getQK(data.side)
          const { q, k, kToMs, mToPossibleOrderingCount } = buildQK(
            gameQKs.q,
            gameQKs.k,
            BigInt(data.numberOfEntries)
          )
          const { k: filledOriginalK } = fillProvidedQK(gameQKs.q, gameQKs.k)
          // console.log(
          //   'maxValues: : ',
          //   ethUsdcPriceSCValue,
          //   ethUsdcPriceBufferPercentage,
          //   averageCallbackGasSCValue,
          //   averageCallbackGasBufferPercentage,
          //   aaCostMultiplierSCValue,
          //   aaCostMultiplierBufferPercentage
          // )
          // @TODO: Get max values and buffer percentage and calculate max values for user and pack those values
          // @TODO: What if user set the bugger with decimal? This would error out because I am using bigints. So, think if we should support that if so find a way to support it
          const maxEthUsdcPrice =
            (BigInt(ethUsdcPriceSCValue) * (100n + BigInt(ethUsdcPriceBufferPercentage))) / 100n
          const maxAverageCallbackGas =
            (BigInt(averageCallbackGasSCValue) *
              (100n + BigInt(averageCallbackGasBufferPercentage))) /
            100n
          const maxAACostMultiplier =
            (BigInt(aaCostMultiplierSCValue) * (100n + BigInt(aaCostMultiplierBufferPercentage))) /
            100n
          const packedMaxValues = packMaxValues(
            maxEthUsdcPrice,
            maxAverageCallbackGas,
            maxAACostMultiplier
          )
          // console.log('maxValues: max eth usdc price: ', maxEthUsdcPrice)
          // console.log('maxValues: max average callback gas: ', maxAverageCallbackGas)
          // console.log('maxValues: max aa cost multiplier: ', maxAACostMultiplier)
          // console.log('maxValues: packed value: ', packedMaxValues)

          // @NOTE: Gas estimation for keccak fails. Therefore, estimate gas as if using vrf and use that gasLmit explicitly when sending the tx
          // @NOTE: Gas usage of Keccak and VRF are fairly similar therefore, this should be fine
          const estimatedGasLimit = await gameContract.estimateGas.trialRegister(
            account, // who
            parseUnits(String(data.entryAmount), USDC_DECIMALS), // multiplier
            q,
            k,
            packedMaxValues // packedMaxValues
          )
          setSubmittedSide(data.side)
          setSUContractResults(initialSUContractResults)
          setTrialData({
            k,
            kToMs,
            mToPossibleOrderingCount,
            filledOriginalK,
          })
          // @NOTE: Set user's gameName so that backend identifies trial's gameName correctly
          await authApi.setGameName((routeGameTypeMap[pathname] as GameNames) || '')
          const submitEntryTx = await gameContract.trialRegister(
            account, // who
            parseUnits(String(data.entryAmount), USDC_DECIMALS), // multiplier
            q,
            k,
            packedMaxValues, // packedMaxValues
            {
              gasLimit: estimatedGasLimit.mul(120).div(100),
            }
          )

          // Add to live entries delay queue
          useDelayedLiveEntry.getState().setSubmittedTxHash(submitEntryTx.hash)
          console.log('setting submitted has to ', submitEntryTx.hash)

          send?.({
            type: 'START',
            payload: {
              side: data.side,
              entryAmount: data.entryAmount,
              entryCount: data.numberOfEntries,
              stopLoss: data.stopLoss,
              stopGain: data.stopGain,
            },
          })

          await submitEntryTx?.wait()
        }

        addAppNoti({
          msg: 'Entry submitted',
          type: 'success',
        })
      } catch (err) {
        setIsSubmitting(false)
        console.log('TEST: error: ', err)
        if (err) {
          console.log('TEST: inside the if')
          send?.({
            type: 'ERROR',
            payload: { errMsg: 'Error with tx' },
          })

          let errMsg = 'Unknown Error'
          if (isUsingAA && zeroDevSessionKeyProvider) {
            console.log(
              'TEST: have (isUsingAA && zeroDevSessionKeyProvider) but still an error: ',
              err
            )
            console.log('TEST: gameContract.address: ', gameContract.address)
            console.log('TEST: trying to decode AA error: ', (err as any)?.cause?.data)
            if ((err as Error)?.message && isKnownErrorMessage((err as Error)?.message)) {
              console.log('thrown err: ', (err as Error).message)
              errMsg = (err as Error).message
            } else {
              errMsg = mapErrorDataToMeaningfullString((err as any)?.cause?.data)
              if (errMsg === 'UnknownError') {
                errMsg = 'Error with Quickplay - try refreshing tab'
              }
            }
          } else {
            if ((err as Error)?.message && isKnownErrorMessage((err as Error)?.message)) {
              console.log('thrown err: ', (err as Error).message)
              errMsg = (err as Error).message
            } else {
              console.log('TEST: trying to decode EOA error: ')
              const decodedError = decodeError(err, SUContractInterface)
              errMsg = mapSCStringToMeaningfulString(decodedError.error)
            }
          }

          // @NOTE: Package found from the following discussion: https://github.com/ethers-io/ethers.js/discussions/3027
          // @NOTE: Still problematic to decode AA related stuff (as expected, because we receive an error from our POST request rather than an error from the geth node), like it will not give the custom error but give something like: "Buffer is not defined"
          // @NOTE: Maybe it might be a good idea to divide eoa and aa error handling?
          // console.log('decoded error: ', decodedError)
          addAppNoti({
            msg: errMsg,
            type: 'error',
          })
          throw new Error(
            `Error while submitting entry, with quickplay: ${isUsingAA && zeroDevSessionKeyProvider}`
          )
        }
      } finally {
        // setIsSubmitting(false)
        fetchAndSetAllowance(account, fareBankrollAddress)
          .then(() => {
            console.log('FETCHING AND SETTING ALLOWANCE')
          })
          .catch(console.error)
      }
    },
    [
      gameContract,
      gameContractWS,
      userAddress,
      account,
      encodedRandomnessType,
      isUsingAA,
      zeroDevSessionKeyProvider,
      send,
      setSUContractResults,
      setTrialData,
      ethUsdcPriceSCValue,
      ethUsdcPriceBufferPercentage,
      averageCallbackGasSCValue,
      averageCallbackGasBufferPercentage,
      aaCostMultiplierSCValue,
      aaCostMultiplierBufferPercentage,
    ]
  )

  // const fetchMaxValuesSCValues = useCallback(async () => {
  //   console.log('account change: gameContract: ', gameContract)
  //   if (!gameContract) return
  //   const config = await gameContract.configView()
  //   console.log('account change: config: ', config)
  //   return {
  //     ethUsdcPrice: config.ethUsdcPrice.toString(),
  //     averageCallbackGas: config.averageCallbackGas,
  //     aaCostMultiplier: config.aaCostMultiplier,
  //   }
  // }, [gameContract])

  return useMemo(
    () => ({
      gameContract,
      gameContractWS,
      account,
      submitEntry,
      // fetchMaxValuesSCValues,
    }),
    [
      gameContract,
      gameContractWS,
      account,
      submitEntry,
      // fetchMaxValuesSCValues
    ]
  )
}
