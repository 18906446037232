import { BORDER_COLORS, FARE_COLORS, SPACING } from '@/design'
import { WheelEventHandler } from 'react'
import ReactSlider, { ReactSliderProps } from 'react-slider'

const SReactSliderWrapper = styled.div<{ $disabled?: boolean }>`
  height: 18px;
  width: 100%;
  position: absolute;
  bottom: -6px;
  ${props =>
    props.$disabled &&
    css`
      pointer-events: none;
    `}
  .fare-input-slider {
    cursor: pointer;
    width: calc(100% - 1.5px);
    height: 18px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;

    .track-0 {
      transition:
        background ease-out 0.16s,
        border-top ease-out 0.16s;
      height: 4px;
      background: ${FARE_COLORS.aqua}50;
      border-top: 1px solid transparent;
      border-bottom-left-radius: 6px;
      border-bottom: 1px solid transparent;
      overflow: hidden;
    }

    .track-1 {
      transition:
        background ease-out 0.16s,
        border-top ease-out 0.16s;
      height: 4px;
      background: #1b1d26;
      border-bottom-right-radius: 6px;
      border-top: 1px solid ${BORDER_COLORS.one};
      border-bottom: 1px solid transparent;
    }

    &:active {
      cursor: grabbing !important;
      .track-0 {
        border-top: 1px solid ${FARE_COLORS.aqua};
        border-bottom: 1px solid ${FARE_COLORS.aqua};
      }
      .track-1 {
        border-bottom: 1px solid ${FARE_COLORS.aqua};
      }
      .thumb-0 {
        background: #428375;
      }
    }
  }
`

const SSliderThumb = styled.div`
  height: 12px;
  width: 12px;
  border-radius: 2px;
  border: 1px solid ${FARE_COLORS.aqua};
  background: ${FARE_COLORS.aqua}50;
  /* cursor: grab; */
  transition: background ease-out 0.16s;
  backdrop-filter: blur(1px);
  &:active {
    background: #428375;
    /* cursor: grabbing; */
  }
  &:focus {
    outline: none;
  }
`

const Thumb = (props: any, _state: any) => {
  return <SSliderThumb {...props} />
}

export const InputSlider = (props: ReactSliderProps) => {
  const handleWheel: WheelEventHandler<HTMLDivElement> = useCallback(
    ev => {
      if (
        typeof props.value !== 'number' ||
        typeof props.step !== 'number' ||
        typeof props.max !== 'number' ||
        typeof props.min !== 'number'
      )
        return

      const { deltaX, deltaY, shiftKey } = ev
      const incrementAmount = shiftKey ? 5 : props.step || 1
      const halfValue = (props.max - props.min) / 2
      let newVal = props.value

      if (deltaX < 0 || deltaY < 0) {
        // Increase the value for the first half of the slider
        if (props.value < halfValue) {
          newVal = Math.min(props.value + incrementAmount, halfValue)
        } else {
          newVal = Math.min(props.value + incrementAmount, props.max)
        }
      }

      if (deltaX > 0 || deltaY > 0) {
        // Decrease the value for the second half of the slider
        if (props.value > halfValue) {
          newVal = Math.max(props.value - incrementAmount, halfValue)
        } else {
          newVal = Math.max(props.value - incrementAmount, props.min)
        }
      }

      props.onChange?.(newVal, 0)
    },
    [props]
  )

  const max = useMemo(() => (props.max && props.max > 20 ? 1_000 : props.max), [props])
  const step = useMemo(() => (props.max && props.max > 20 ? 100 : props.step), [props])

  return (
    <SReactSliderWrapper
      id='fare-input-slider-wrapper'
      onWheel={handleWheel}
      $disabled={props.disabled}
    >
      <ReactSlider
        className='fare-input-slider'
        min={0}
        // max={100}
        defaultValue={50}
        renderThumb={Thumb}
        {...props}
        max={max}
        step={step}
        marks
      />
    </SReactSliderWrapper>
  )
}
