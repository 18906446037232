import { Button, ButtonEnum } from '.'
import {
  BankrollContractInterface,
  type GameNames,
  MAX_APPROVAL_AMOUNT,
  VITE_USDC_VAULT_ADDRESS,
  fareBankrollAddress,
  formatUsdc,
  getGameAddressFromRoute,
  // getKellyFraction,
  getPotentialProfitCoefficient,
  routeGameTypeMap,
} from '@/lib/crypto'
import useCurrencyStore from '@/store/useCurrencyStore'
import { useShallow } from 'zustand/react/shallow'
import { useCurrencyContracts } from '../Singleton/useCurrencyContracts'
import ConnectWallet from '../Wallet/ConnectWallet'
import useSUContractStore from '@/store/useSUContractStore'
import { useGameContract } from '../Singleton/useGameContract'
import { type Timeout } from 'react-number-format/types/types'
import { DEFAULT_CHAIN_ID } from '@/constants/web3'
import { useWeb3 } from '@/hooks/useWeb3'
import { useWeb3Modal } from '@web3modal/ethers5/react'
import { addAndSwitchNetwork } from '@/lib/crypto/network'
import { decodeError } from 'ethers-decode-error'
import { addAppNoti } from '@/store/useNotiStore'
import numeral from 'numeral'
import { fsocket } from '@/lib/fsocket'
import { useIsAuthed } from '@/hooks/useIsAuthed'
import useBankrollStore from '@/store/useBankrollStore'

interface IGameButton {
  entryAmountNum: number
  formData: any
}

export const GameButton = ({ entryAmountNum, formData }: IGameButton) => {
  const { open } = useWeb3Modal()
  const { account, chainId, provider, isActive } = useWeb3()
  const { pathname } = useLocation()
  const selectedGameContractAddress = useMemo(
    () => getGameAddressFromRoute(pathname) as string,
    [pathname]
  )
  const { riskFactor } = useBankrollStore()
  const { approveAllowance, bankrollContract } = useCurrencyContracts()
  const selectedCurrency = 'usdc'
  const { selectedAllowance, isApprovingAllowance, selectedBalance } = useCurrencyStore(
    useShallow(state => ({
      isApprovingAllowance: state.isApprovingAllowance,
      selectedBalance: state.balances.usdc,
      // bankrollUsdcAmount: state.balances.bankroll,
      selectedAllowance: state.allowances.usdc,
    }))
  )
  const { submitEntry } = useGameContract(routeGameTypeMap[pathname] as any)
  // const hasLoadedInitial = useWalletStore((state) => state.hasLoadedInitial)
  const {
    isApprovingContracts,
    hasApprovedContracts,
    inProgressEntry,
    isWithdrawing,
    isSubmitting,
    setIsApprovingContracts,
    setHasApprovedContracts,
  } = useSUContractStore(state => ({
    isApprovingContracts: state.isApprovingContracts,
    hasApprovedContracts: state.hasApprovedContracts,
    inProgressEntry: state.inProgressEntry,
    isWithdrawing: state.isWithdrawing,
    isSubmitting: state.isSubmitting,
    setIsApprovingContracts: state.setIsApprovingContracts,
    setHasApprovedContracts: state.setHasApprovedContracts,
  }))
  const [canWithdraw, setCanWithdraw] = useState(false)
  const { bankrollBalance } = fsocket.user.useState()
  const isAuthed = useIsAuthed()

  // const kellyFraction = useMemo(() => {
  //   return getKellyFraction(selectedGameContractAddress, formData.side)
  // }, [selectedGameContractAddress, formData.side])

  const potentialProfitCoefficient = useMemo(() => {
    return getPotentialProfitCoefficient(routeGameTypeMap[pathname] as GameNames, formData.side)
  }, [formData.side, pathname])

  const maxAllowedBet = useMemo(() => {
    console.log('maxAllowedBet updated bankroll')
    // @NOTE: If there is no data about bankrollBalance, set it to max value. As a result, user would not have the feature of seeing kelly limit
    if (!bankrollBalance) return Number.MAX_SAFE_INTEGER
    let risk = 100 // by default risk is 100 to represent 1.00
    if (riskFactor) {
      risk = riskFactor
    }
    // return Number(formatUsdc(bankrollBalance)) * Math.abs(kellyFraction)
    const maxBet =
      // since risk has 2 basis points, we have to divie by 100 to normalize it
      // since risk is represented in %, like if risk is 1.00% it means that we are fine with risking 1% of the bankroll
      // so, I divide risk by 100 get rid of basis points and divide by 100 again to make it so that it works by multiplying bankroll balance so I can just divide by 10000 once
      // when it comes to potentialProfitCoefficient, if you flip coin flip, it's potentialProfitCoefficient will be 0.98
      (Number(formatUsdc(bankrollBalance)) * (risk / 10000)) / potentialProfitCoefficient
    console.log('bankrollBalance', maxBet, bankrollBalance, potentialProfitCoefficient, riskFactor)
    return maxBet
  }, [potentialProfitCoefficient, bankrollBalance, riskFactor])

  const formattedMaxAllowedBet = useMemo(
    () => numeral(Math.floor(maxAllowedBet)).format('0,0.00'),
    [maxAllowedBet]
  )

  useEffect(() => {
    let intervalId: Timeout
    if (inProgressEntry) {
      const intervalFunc = () => {
        if (Date.now() > inProgressEntry.timestamp + 60000) {
          setCanWithdraw(true)
        }
      }
      intervalFunc()
      intervalId = setInterval(intervalFunc, 1_000)
    } else {
      setCanWithdraw(false)
    }

    return () => clearInterval(intervalId)
  }, [inProgressEntry])

  // @TODO: Abstract
  const approveContracts = useCallback(async () => {
    try {
      setIsApprovingContracts(true)
      if (!bankrollContract) {
        console.warn('bankrollContract undefined')
        return
      }
      const allowConsumerContractsTx = await bankrollContract.setAllowedContracts(
        [VITE_USDC_VAULT_ADDRESS],
        [true]
      )
      await allowConsumerContractsTx.wait()
      console.log('allowed consumer games')
      setHasApprovedContracts(true)
    } catch (err) {
      // @NOTE: Package found from the following discussion: https://github.com/ethers-io/ethers.js/discussions/3027
      // @NOTE: Still problematic to decode AA related stuff (as expected, because we receive an error from our POST request rather than an error from the geth node), like it will not give the custom error but give something like: "Buffer is not defined"
      // @NOTE: Maybe it might be a good idea to divide eoa and aa error handling?
      const decodedError = decodeError(err, BankrollContractInterface)
      console.log('decoded error: ', decodedError)
      addAppNoti({
        msg: decodedError.error,
        type: 'error',
      })
      throw new Error(`Error approving contracts`)
    } finally {
      setIsApprovingContracts(false)
    }
  }, [bankrollContract, setHasApprovedContracts, setIsApprovingContracts, provider, chainId])

  useEffect(() => {
    ;(async () => {
      if (!bankrollContract || !account) return
      const isApproved = await bankrollContract.isValidContractForFundOwner(
        VITE_USDC_VAULT_ADDRESS,
        account
      )
      setHasApprovedContracts(isApproved)
    })()
  }, [bankrollContract, account, setHasApprovedContracts, provider, chainId])

  const hasApproved = useMemo(
    () =>
      Number(entryAmountNum) === 0 ||
      (Number(selectedAllowance) !== 0 && Number(selectedAllowance) >= entryAmountNum),
    [selectedAllowance, entryAmountNum]
  )

  const buttonRenderer = useMemo(() => {
    if (!isAuthed) {
      return <ConnectWallet />
    }

    if (chainId !== DEFAULT_CHAIN_ID) {
      return (
        <Button
          onClick={() => addAndSwitchNetwork(provider)}
          buttonType={ButtonEnum.WARNING}
          disabled={chainId === DEFAULT_CHAIN_ID}
          loadingText={'SWITCHING NETWORK'}
          type='button'
        >
          SWITCH NETWORK
        </Button>
      )
    }

    if (!hasApprovedContracts) {
      return (
        <Button
          onClick={approveContracts}
          buttonType={ButtonEnum.CONNECT_WALLET}
          disabled={isApprovingContracts}
          isLoading={isApprovingContracts}
          loadingText={'APPROVING GAMES'}
          type='button'
        >
          APPROVE GAMES
        </Button>
      )
    }

    if (inProgressEntry) {
      return (
        <Button
          onClick={() => {}}
          buttonType={ButtonEnum.PRIMARY_1}
          disabled={isWithdrawing || !canWithdraw}
          isLoading={isWithdrawing}
          loadingText={'PLAYING'}
          type='button'
        >
          PLAYING
        </Button>
      )
    }

    if (hasApproved) {
      // @TODO: Might be working wrong right now, because of the update of logic
      // if (entryAmountNum / (formData.numberOfEntries || 1) > maxAllowedBet) {
      // @NOTE: With usdcVault's riskFactor, formData.numberOfEntries fo not better
      if (entryAmountNum > maxAllowedBet) {
        return (
          <Button
            onClick={() => {}}
            buttonType={ButtonEnum.PRIMARY_1}
            disabled={true}
            isLoading={isSubmitting}
            loadingText={'SUBMITTING ENTRY'}
            type='button'
          >
            LIMIT: {formattedMaxAllowedBet}
          </Button>
        )
      }

      return (
        <Button
          onClick={() => submitEntry(formData)}
          buttonType={ButtonEnum.PRIMARY_1}
          disabled={isSubmitting || entryAmountNum <= 0}
          isLoading={isSubmitting}
          loadingText={'SUBMITTING ENTRY'}
          type='button'
        >
          SUBMIT ENTRY
        </Button>
      )
    } else {
      return (
        <Button
          onClick={() => approveAllowance(fareBankrollAddress, MAX_APPROVAL_AMOUNT, 'usdc')}
          buttonType={ButtonEnum.CONNECT_WALLET}
          disabled={isApprovingAllowance || entryAmountNum <= 0}
          isLoading={isApprovingAllowance}
          loadingText={'APPROVING USDC'}
          type='button'
        >
          APPROVE {selectedCurrency.toUpperCase()}
        </Button>
      )
    }
  }, [
    bankrollBalance,
    hasApprovedContracts,
    isActive,
    chainId,
    inProgressEntry,
    hasApproved,
    provider,
    approveContracts,
    isApprovingContracts,
    isWithdrawing,
    canWithdraw,
    isSubmitting,
    entryAmountNum,
    submitEntry,
    formData,
    isApprovingAllowance,
    approveAllowance,
  ])

  return <>{buttonRenderer}</>
}
