import { useWeb3 } from './useWeb3'
import { type UsdcVault, UsdcVault__factory } from '@/lib/crypto/typechain'
import { VITE_USDC_VAULT_ADDRESS } from '@/lib/crypto'
import { useEffectOnce } from 'usehooks-ts'
import useMaxValuesStore from '@/store/useMaxValuesStore'

// export const DEFAULT_MAX_VALUES = {
//   ethUsdcPriceBuffer: DEFAULT_BUFFER_PERCENTAGE,
//   averageCallbackGasBuffer: DEFAULT_BUFFER_PERCENTAGE,
//   aaCostMultiplierBuffer: DEFAULT_BUFFER_PERCENTAGE,
//   ethUsdcPriceSCValue: '', // @NOTE: These values should be fetched from smart contract. 'FetchCurrentMaxValues' component does fetch does values and sets them inside this object
//   averageCallbackGasSCValue: '', // @NOTE: These values should be fetched from smart contract. 'FetchCurrentMaxValues' component does fetch does values and sets them inside this object
//   aaCostMultiplierSCValue: '', // @NOTE: These values should be fetched from smart contract. 'FetchCurrentMaxValues' component does fetch does values and sets them inside this object
// }

// @NOTE: Local storage expected data structure:
//      max-values:
//              [
//                  "0x6a5feae5961ac6261afe19a4cd737a9528da4e3f": {
//                      ethUsdcPriceBuffer: "10"
//                      averageCallbackGasBuffer: "10"
//                      aaCostMultiplierBuffer: "10"
//                      ethUsdcPriceBufferSCValue: "2650.456456"
//                      averageCallbackGasSCValue: "1000000"
//                      aaCostMultiplierSCValue: "110"
//                   },
//                  "0xc03872c161afe24119a8b36af0f2be739cea8946": {
//                      ethUsdcPriceBuffer: "20"
//                      averageCallbackGasBuffer: "12"
//                      aaCostMultiplierBuffer: "1"
//                      ethUsdcPriceBufferSCValue: "2850.456456"
//                      averageCallbackGasSCValue: "700000"
//                      aaCostMultiplierSCValue: "130"
//                   },
//              ]

const useMaxValues = () => {
  const { setSCValues } = useMaxValuesStore()
  const { provider } = useWeb3()

  const gameContract = useMemo(() => {
    if (!provider) return null
    return UsdcVault__factory.connect(VITE_USDC_VAULT_ADDRESS, provider.getSigner()) as UsdcVault
  }, [provider])

  const fetchSCMaxValues = useCallback(async () => {
    if (!gameContract) return
    const config = await gameContract.configView()
    console.log('account change: config: ', config)
    const scVals = {
      ethUsdcPriceSCValue: config.ethUsdcPrice.toString(),
      averageCallbackGasSCValue: String(config.averageCallbackGas),
      aaCostMultiplierSCValue: String(config.aaCostMultiplier),
    }
    return scVals
  }, [gameContract])

  const fetchAndSetSCMaxValues = useCallback(
    async (account: string) => {
      const scMaxValues = await fetchSCMaxValues()
      if (!scMaxValues) return
      console.log('sc max values: ', scMaxValues)
      setSCValues(scMaxValues, account)
    },
    [setSCValues, fetchSCMaxValues]
  )

  return { fetchSCMaxValues, fetchAndSetSCMaxValues }
}

export default useMaxValues
