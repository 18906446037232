import { Suspense } from 'react'
import { Canvas } from '@react-three/fiber'
import { Bloom, EffectComposer } from '@react-three/postprocessing'
import { Physics } from '@react-three/rapier'

import { DiceScene } from './DiceScene'
import { BREAKPOINTS, BORDER_COLORS, COMPONENTS } from '@/design'
import { useDidIRender } from '@/hooks/common/useDidIRender'
import { AnimatePresence, motion } from 'framer-motion'
import { DeltaNumbersDisplay } from '@/components/DeltaNumbersDisplay'
import { fareDiceAddress, GameNames } from '@/lib/crypto'
import { LoadingChip } from '@/components/shared/LoadingChip'

const SDiceCanvas = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 6px;
  border: 1px solid ${BORDER_COLORS.one};
  backdrop-filter: blur(2px);
  grid-column: 1 / span 5;
  overflow: hidden;
  position: relative;

  /* Vendor prefixes */
  -webkit-backdrop-filter: blur(2px); /* Safari and Chrome */
  -moz-backdrop-filter: blur(2px); /* Firefox */
  -ms-backdrop-filter: blur(2px); /* Edge */

  @media only screen and (max-width: ${BREAKPOINTS.sm}px) {
    border: none;
    height: ${COMPONENTS.mobileGameView}px;
    backdrop-filter: blur(0px);
    /* Vendor prefixes */
    -webkit-backdrop-filter: blur(0px); /* Safari and Chrome */
    -moz-backdrop-filter: blur(0px); /* Firefox */
    -ms-backdrop-filter: blur(0px); /* Edge */
  }
`

const SMounting = styled(motion.div)`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  backdrop-filter: blur(2px);
  /* Vendor prefixes */
  -webkit-backdrop-filter: blur(2px); /* Safari and Chrome */
  -moz-backdrop-filter: blur(2px); /* Firefox */
  -ms-backdrop-filter: blur(2px); /* Edge */
  /* background: black; */
  user-select: none;
`

interface IDiceCanvas {
  isMounted: boolean
  setIsMounted: (isMounted: boolean) => void
}

function DiceCanvas({ isMounted, setIsMounted }: IDiceCanvas) {
  // const [isMounted, setIsMounted] = useState(false)
  const containerRef = useRef<HTMLDivElement>(null)
  const canvasRef = useRef<HTMLCanvasElement>(null)
  const gravityY = -30
  useDidIRender('DiceCanvas')

  const gravity = useMemo(() => [0, gravityY, 0] as [number, number, number], [gravityY])

  return (
    <SDiceCanvas ref={containerRef} style={{ opacity: isMounted ? 1 : 0 }}>
      <DeltaNumbersDisplay gameName={GameNames.Dice} />
      {/* <AnimatePresence> */}
      {/*   {!isMounted && ( */}
      {/*     <SMounting initial={{ opacity: 1 }} exit={{ opacity: 0 }} transition={{ duration: 0.6 }}> */}
      {/*       LOADING DICE... */}
      {/*     </SMounting> */}
      {/*   )} */}
      {/* </AnimatePresence> */}
      <Canvas
        className='dice-canvas'
        ref={canvasRef}
        onCreated={_state => {
          setTimeout(() => {
            setIsMounted(true)
          }, 600)
        }}
        shadows
        dpr={[1, 2]}
        gl={{ antialias: true, stencil: false, preserveDrawingBuffer: false, alpha: true }}
      >
        <EffectComposer disableNormalPass>
          <Bloom luminanceThreshold={1.35} luminanceSmoothing={0.8} intensity={1} />
        </EffectComposer>
        <fog attach='fog' args={[0x090909, 5, 15]} />
        <Suspense fallback={null}>
          <Physics debug={false} gravity={gravity}>
            <DiceScene />
          </Physics>
        </Suspense>
      </Canvas>
    </SDiceCanvas>
  )
}

export default DiceCanvas
