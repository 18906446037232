import { SVGS } from '@/assets'
import { BaseModal } from '..'
import { Button, ButtonEnum } from '../../Button'
import { Input } from '../../Input'
import {
  LoadingWrapper,
  QuickplayStrip,
  SpinnerWrapper,
  SwapInputWrapper,
  SwapWrapper,
  Title,
} from './style'
import { motion } from 'framer-motion'
import { Spinner } from '../../Spinner'
import { HeaderStrip } from '../../Header/style'
import { SPACING } from '@/design'
import useCurrencyStore from '@/store/useCurrencyStore'

interface ISwapModalProps {
  show: boolean
  setShow: (show: boolean) => void
}

enum SwapPageEnum {
  SWAP,
  CONFIRM,
}

const loadingTexts = [
  'Spawning migration wizards',
  'Summoning the blockchain',
  'Converting ETH to fareETH',
]

export const SwapModal = ({ show, setShow }: ISwapModalProps) => {
  const [currentPage, setPage] = useState<number>(SwapPageEnum.SWAP)
  const [ethAmount, setEthAmount] = useState<number>(0)
  const { selectedBalance } = useCurrencyStore(state => ({ selectedBalance: state.balances.usdc }))
  const [displayText, setDisplayText] = useState<string>()

  useEffect(() => {
    const timer = setInterval(() => {
      setDisplayText(loadingTexts[Math.floor(Math.random() * loadingTexts.length)])
    }, 2000)
    return () => {
      clearInterval(timer)
    }
  }, [])

  const footerActions = useMemo(() => {
    return (
      <>
        <Button
          disabled={false}
          isLoading={currentPage === 1}
          loadingText={'Depositing'}
          buttonType={ButtonEnum.QUICKPLAY}
          onClick={() => setPage(currentPage === 0 ? 1 : 0)}
          style={{ width: '100%' }}
        >
          DEPOSIT
        </Button>
      </>
    )
  }, [currentPage])

  return (
    <BaseModal
      noPadding
      show={show}
      setShow={setShow}
      title={'QUICKPLAY'}
      width={350}
      content={
        <motion.div
          key={currentPage}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.5 }}
        >
          {currentPage === SwapPageEnum.SWAP ?
            <SwapWrapper>
              <SwapInputWrapper>
                <Title>
                  <span>You deposit:</span>
                  <span>Balance: {selectedBalance} ETH</span>
                </Title>
                <Input
                  placeholder='0.0'
                  isNumeric
                  value={ethAmount}
                  onChange={e => setEthAmount(Number(e.target.value))}
                  inputPrefix={<img height={40} src={SVGS.ethIcon} alt='fare-eth' />}
                  inputSuffix={
                    <Button
                      buttonType={ButtonEnum.BASE}
                      disabled={false}
                      isLoading={false}
                      onClick={() => setEthAmount(Number(selectedBalance))}
                    >
                      Max
                    </Button>
                  }
                />
              </SwapInputWrapper>

              <SwapInputWrapper>
                <Title>
                  <span>You receive:</span>
                </Title>
                <Input
                  style={{ border: 'none' }}
                  placeholder='0.0'
                  disabled
                  value={ethAmount}
                  // inputPrefix={<img height={40} src={SVGS.fareETHIcon} alt="fare-eth" />}
                />
              </SwapInputWrapper>
            </SwapWrapper>
          : <LoadingWrapper>
              <img src={SVGS.ethIcon} alt='eth' />
              <SpinnerWrapper>
                {/* <Spinner blockSize={8} blockGap={4} mode="default" /> */}
                <QuickplayStrip />
                <motion.span
                  key={displayText}
                  transition={{
                    duration: 1,
                    ease: 'easeInOut',
                  }}
                  initial={{
                    opacity: 0,
                  }}
                  animate={{
                    opacity: 1,
                  }}
                  exit={{
                    opacity: 0,
                  }}
                  // style={{ marginTop: SPACING.sm }}
                >
                  {displayText}
                </motion.span>
              </SpinnerWrapper>
              {/* <img src={SVGS.fareETHIcon} alt="fare-eth" /> */}
            </LoadingWrapper>
          }
        </motion.div>
      }
      footer={footerActions}
    />
  )
}
