import { PNGS } from '@/assets'
import useAuth from '@/hooks/useAuth'
import useAuthStore from '@/store/useAuthStore'
import { useWeb3ModalAccount } from '@web3modal/ethers5/react'
import { AnimatePresence, Variants, motion } from 'framer-motion'
import { useDebouncedCallback } from 'use-debounce'
import { useShallow } from 'zustand/react/shallow'
import { PermaInviteCodeModal } from './PermaInviteCode'
import { useWeb3 } from '@/hooks/useWeb3'
import { COLORS, FARE_COLORS } from '@/design'

const SAuthWalletOverlay = styled(motion.div)`
  position: fixed;
  height: 100vh;
  width: 100vw;
  background: rgba(0, 0, 0, 0.6);
  backdrop-filter: blur(4px);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 999;
`

const SRouletteTable = styled.div`
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  > img {
    width: 600px;
    height: 600px;
    position: absolute;
  }

  > span {
    position: absolute;
    font-size: 16px;
    width: 600px;
    text-align: center;
    text-shadow:
      1px 1px 8.3px rgba(255, 255, 255, 0.5),
      1px 1px 8.3px rgba(255, 255, 255, 0.5);
  }
`

const SDisconnectBtn = styled.button`
  all: unset;
  color: white;
  position: absolute;
  right: 24px;
  top: 18px;
  border: 1px solid transparent;
  padding: 12px 18px;
  transition: all ease-in-out 0.08s;
  border-radius: 6px;

  &:hover {
    color: ${FARE_COLORS.aqua};
    cursor: pointer;
    border: 1px solid ${FARE_COLORS.aqua};
  }
`

const authWalletOverlayVariants: Variants = {
  initial: { opacity: 0, scaleY: 0 },
  animate: { opacity: 1, scaleY: 1 },
  exit: { opacity: 0, scaleY: 0 },
}

export const AuthWalletOverlay = () => {
  const isAuthing = useAuthStore(useShallow(state => state.isAuthing))
  const { isActive: isConnected, account: address, disconnect } = useWeb3()
  const { authenticate, authToken } = useAuth()
  const hasEnteredInviteCode = useAuthStore(useShallow(state => state.hasEnteredInviteCode))

  const deactivate = useCallback(async () => {
    if (!isConnected) return

    await disconnect()
  }, [isConnected, disconnect])

  const deactiveAndLogout = useCallback(async () => {
    try {
      console.log('called disconnect')
      await deactivate()
      // navigate('/')
      window.location.reload()
    } catch (err) {
      console.error(err)
    }
  }, [deactivate])

  const debouncedAuthenticate = useDebouncedCallback(authenticate, 200, { leading: false })

  useEffect(() => {
    if (isConnected && address && !authToken) {
      debouncedAuthenticate()
    }
  }, [isConnected, address, authToken])

  const shouldShowAuthWalletOverlay = useMemo(() => {
    return isAuthing || (!hasEnteredInviteCode && isConnected && authToken)
  }, [isAuthing, hasEnteredInviteCode, authToken, isConnected])

  return (
    <AnimatePresence>
      {shouldShowAuthWalletOverlay && (
        <SAuthWalletOverlay
          variants={authWalletOverlayVariants}
          initial='initial'
          animate='animate'
          exit='exit'
        >
          <SRouletteTable>
            <img alt='Roulette table' src={PNGS.rouletteTable} />
            {isAuthing ?
              <span>Sign the message in your wallet to authenticate</span>
            : <PermaInviteCodeModal isCloseable={false} onSuccess={() => {}} />}
          </SRouletteTable>
          <SDisconnectBtn onClick={deactiveAndLogout}>DISCONNECT</SDisconnectBtn>
        </SAuthWalletOverlay>
      )}
    </AnimatePresence>
  )
}
