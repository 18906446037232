import { LOCAL_STORAGE } from '@/constants/utils'
import { create } from 'zustand'
import { immer } from 'zustand/middleware/immer'

// @TODO: Should have default value of 10% for buffers
export const DEFAULT_BUFFER_PERCENTAGE = '10'
export const DEFAULT_BUFFER_VALUES = {
  ethUsdcPriceBufferPercentage: DEFAULT_BUFFER_PERCENTAGE,
  averageCallbackGasBufferPercentage: DEFAULT_BUFFER_PERCENTAGE,
  aaCostMultiplierBufferPercentage: DEFAULT_BUFFER_PERCENTAGE,
}

export const setMaxValuesInLocalStorage = (
  maxValues: IMaxValuesLocalStorageValues,
  publicAddress: string
) => {
  let maxValuesStr = localStorage.getItem(LOCAL_STORAGE.MAX_VALUES)
  if (!maxValuesStr) {
    localStorage.setItem(LOCAL_STORAGE.MAX_VALUES, JSON.stringify({}))
    maxValuesStr = localStorage.getItem(LOCAL_STORAGE.MAX_VALUES)
  }
  localStorage.setItem(
    LOCAL_STORAGE.MAX_VALUES,
    JSON.stringify({ ...JSON.parse(maxValuesStr!), [publicAddress]: maxValues })
  )
}

export const getMaxValuesFromLocalStorage = (
  publicAddress: string,
  defaultSCVals: any
): IMaxValuesLocalStorageValues => {
  const maxValuesStr = localStorage.getItem(LOCAL_STORAGE.MAX_VALUES)
  if (!maxValuesStr) return { ...defaultSCVals, ...DEFAULT_BUFFER_VALUES }
  return JSON.parse(maxValuesStr)[publicAddress] ?
      JSON.parse(maxValuesStr)[publicAddress]
    : { ...defaultSCVals, ...DEFAULT_BUFFER_VALUES }
}

export const setMaxValueInLocalStorage = (
  fieldName: string,
  fieldValue: string,
  publicAddress: string
) => {
  let maxValuesStr = localStorage.getItem(LOCAL_STORAGE.MAX_VALUES)
  if (!maxValuesStr) {
    localStorage.setItem(LOCAL_STORAGE.MAX_VALUES, JSON.stringify({}))
    maxValuesStr = localStorage.getItem(LOCAL_STORAGE.MAX_VALUES)
  }
  const maxValues = JSON.parse(maxValuesStr as string)
  if (maxValues[publicAddress]) {
    maxValues[publicAddress][fieldName] = fieldValue
  }
  localStorage.setItem(
    LOCAL_STORAGE.MAX_VALUES,
    JSON.stringify({ ...JSON.parse(maxValuesStr!), [publicAddress]: maxValues[publicAddress] })
  )
}

export interface IMaxValuesLocalStorageValues {
  ethUsdcPriceBufferPercentage: string
  averageCallbackGasBufferPercentage: string
  aaCostMultiplierBufferPercentage: string
  ethUsdcPriceSCValue: string
  averageCallbackGasSCValue: string
  aaCostMultiplierSCValue: string
}

export interface ISCMaxValues {
  ethUsdcPriceSCValue: string
  averageCallbackGasSCValue: string
  aaCostMultiplierSCValue: string
}
export interface IBufferMaxValues {
  ethUsdcPriceBufferPercentage: string
  averageCallbackGasBufferPercentage: string
  aaCostMultiplierBufferPercentage: string
}

export interface IMaxValues {
  ethUsdcPrice: string
  averageCallbackGas: string
  aaCostMultiplier: string
}

export type MaxValuesState = {
  // @NOTE: SCValue: Smart Contract Value, it is basically the value we will be fetching from smart contract
  ethUsdcPriceSCValue?: string | null
  averageCallbackGasSCValue?: string | null
  aaCostMultiplierSCValue?: string | null
  ethUsdcPriceBufferPercentage?: string | null
  averageCallbackGasBufferPercentage?: string | null
  aaCostMultiplierBufferPercentage?: string | null
}

export type MaxValuesActions = {
  setEthUsdcPriceSCValue: (ethUsdcPriceSCValue: string, account: string) => void
  setAverageCallbackGasSCValue: (averageCallbackGasSCValue: string, account: string) => void
  setAACostMultiplierSCValue: (aaCostMultiplierSCValue: string, account: string) => void
  setEthUsdcPriceBufferPercentage: (ethUsdcPriceBufferPercentage: string, account: string) => void
  setAverageCallbackGasBufferPercentage: (
    averageCallbackGasBufferPercentage: string,
    account: string
  ) => void
  setAACostMultiplierBufferPercentage: (
    aaCostMultiplierBufferPercentage: string,
    account: string
  ) => void
  setMaxValues: (maxValues: IMaxValuesLocalStorageValues, account: string) => void
  setSCValues: (scValues: ISCMaxValues, account: string) => void
}

export const initialMaxValuesState: MaxValuesState = {
  ethUsdcPriceSCValue: null,
  averageCallbackGasSCValue: null,
  aaCostMultiplierSCValue: null,
  ethUsdcPriceBufferPercentage: null,
  averageCallbackGasBufferPercentage: null,
  aaCostMultiplierBufferPercentage: null,
}

export type MaxValuesStore = MaxValuesState & MaxValuesActions

const useMaxValuesStore = create<MaxValuesStore, [['zustand/immer', MaxValuesStore]]>(
  immer(set => ({
    ...initialMaxValuesState,
    // @TODO: All these things should update the local storage rather than just state
    setEthUsdcPriceSCValue: (ethUsdcPriceSCValue, account) =>
      set(state => {
        state.ethUsdcPriceSCValue = ethUsdcPriceSCValue
        setMaxValueInLocalStorage('ethUsdcPriceSCValue', ethUsdcPriceSCValue, account)
      }),
    setAverageCallbackGasSCValue: (averageCallbackGasSCValue, account) =>
      set(state => {
        state.averageCallbackGasSCValue = averageCallbackGasSCValue
        setMaxValueInLocalStorage('averageCallbackGasSCValue', averageCallbackGasSCValue, account)
      }),
    setAACostMultiplierSCValue: (aaCostMultiplierSCValue, account) =>
      set(state => {
        state.aaCostMultiplierSCValue = aaCostMultiplierSCValue
        setMaxValueInLocalStorage('aaCostMultiplierSCValue', aaCostMultiplierSCValue, account)
      }),
    setEthUsdcPriceBufferPercentage: (ethUsdcPriceBufferPercentage, account) =>
      set(state => {
        state.ethUsdcPriceBufferPercentage = ethUsdcPriceBufferPercentage
        setMaxValueInLocalStorage(
          'ethUsdcPriceBufferPercentage',
          ethUsdcPriceBufferPercentage,
          account
        )
      }),
    setAverageCallbackGasBufferPercentage: (averageCallbackGasBufferPercentage, account) =>
      set(state => {
        state.averageCallbackGasBufferPercentage = averageCallbackGasBufferPercentage
        setMaxValueInLocalStorage(
          'averageCallbackGasBufferPercentage',
          averageCallbackGasBufferPercentage,
          account
        )
      }),
    setAACostMultiplierBufferPercentage: (aaCostMultiplierBufferPercentage, account) =>
      set(state => {
        state.aaCostMultiplierBufferPercentage = aaCostMultiplierBufferPercentage
        setMaxValueInLocalStorage(
          'aaCostMultiplierBufferPercentage',
          aaCostMultiplierBufferPercentage,
          account
        )
      }),
    setMaxValues: (maxValues, account) =>
      set(state => {
        state.ethUsdcPriceSCValue = maxValues.ethUsdcPriceSCValue
        state.averageCallbackGasSCValue = maxValues.averageCallbackGasSCValue
        state.aaCostMultiplierSCValue = maxValues.aaCostMultiplierSCValue
        state.ethUsdcPriceBufferPercentage = maxValues.ethUsdcPriceBufferPercentage
        state.averageCallbackGasBufferPercentage = maxValues.averageCallbackGasBufferPercentage
        state.aaCostMultiplierBufferPercentage = maxValues.aaCostMultiplierBufferPercentage
        setMaxValuesInLocalStorage(maxValues, account)
      }),
    setSCValues: (scValues, account) =>
      set(state => {
        state.ethUsdcPriceSCValue = scValues.ethUsdcPriceSCValue
        setMaxValueInLocalStorage('ethUsdcPriceSCValue', scValues.ethUsdcPriceSCValue, account)
        state.averageCallbackGasSCValue = scValues.averageCallbackGasSCValue
        setMaxValueInLocalStorage(
          'averageCallbackGasSCValue',
          scValues.averageCallbackGasSCValue,
          account
        )
        state.aaCostMultiplierSCValue = scValues.aaCostMultiplierSCValue
        setMaxValueInLocalStorage(
          'aaCostMultiplierSCValue',
          scValues.aaCostMultiplierSCValue,
          account
        )
      }),
  }))
)

export default useMaxValuesStore
