import {
  BACKGROUND_COLORS,
  BORDER_COLORS,
  COLORS,
  COMPONENTS,
  FARE_COLORS,
  FONT_STYLES,
  SPACING,
  TEXT_COLORS,
  floatingContainer,
} from '@/design'
import { PageWrapper } from '../style'
import {
  MobilePointsAssistsBorder,
  MobilePointsDeathsBorder,
  MobilePointsKillsBorder,
  MobilePointsMultiplierBorder,
  PointsAssistBorder,
  PointsDeathsBorder,
  PointsKillsBorder,
  PointsMultiplierBorder,
} from './PointsBorders'
import { deviceBP } from '@/design/breakpoints'

export const PointsPageWrapper = styled.div`
  max-height: calc(100vh - ${COMPONENTS.header + SPACING.md * 2 + 0}px);
  display: grid;
  height: 100%;
  border: 1px solid ${BORDER_COLORS.one};
  border-radius: 6px;
  background: ${BACKGROUND_COLORS.two};
  grid-template-rows: auto 1fr;
  z-index: 2;
  /* overflow: auto; */
  width: 100%;
  box-sizing: border-box;
  ${floatingContainer}
  background: transparent;
  backdrop-filter: blur(1px);
  @media (max-width: 1295px) {
    margin-right: 12px;
    margin-left: 12px;
  }

  @media ${deviceBP.sm} {
    max-height: calc(100vh - ${COMPONENTS.header}px);
  }
`

export const MobilePointsPageWrapper = styled(PageWrapper)`
  position: relative;
  max-height: calc(100vh - ${COMPONENTS.header}px);
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  padding: 0px calc(${SPACING.sm}px + 2.5px);
`

export const PointsBodyContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: ${SPACING.xxl}px 16px;
  padding-top: 16px;
  padding-bottom: 16px;
  gap: 0px;
  /* overflow: auto; */
  height: 100%;
  box-sizing: border-box;
  min-height: 0;
  flex-grow: 1;
  background: ${BACKGROUND_COLORS.two};

  @media ${deviceBP.sm} {
    padding-top: 8px;
    padding-bottom: 8px;
    height: 100%;
    min-height: 0;
    flex-grow: 1;
  }
`

export const MobilePointsBodyContainer = styled(PointsBodyContainer)`
  gap: 32px;
  width: 100%;
`

export const PointsUpperContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  /* @media (max-width: 1200px) { */
  /*   flex-direction: column; */
  /*   align-items: center; */
  /* } */

  @media ${deviceBP.sm} {
    align-items: center;
  }
`

export const MobilePointsUpperWrapper = styled.div`
  display: flex;
  gap: ${SPACING.lg}px;
  @media (max-width: 1200px) {
    flex-direction: column;
  }
`

export const MobilePointsUpperContainer = styled(PointsUpperContainer)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`

export const PointsLeftContainer = styled.div`
  display: flex;
  flex-direction: column;

  @media ${deviceBP.sm} {
    width: 100%;
  }
`

export const PointsUserAvatarInfoWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 18px;

  @media ${deviceBP.sm} {
    align-self: center;
    margin-bottom: 12px;
  }
`

export const MobilePointsUserAvatarInfoWrapper = styled(PointsUserAvatarInfoWrapper)`
  flex-direction: column;
  gap: 13px;
`

export const TwitterAvatarPFP = styled.div`
  width: 92px;
  height: 92px;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  .twitter-pfp {
    width: 58px;
    height: 58px;
    border-radius: 100%;
  }

  @media ${deviceBP.sm} {
    width: 48px;
    height: 48px;

    .twitter-pfp {
      width: 32px;
      height: 32px;
    }
  }
`

export const PointsAvatarContainer = styled.div`
  position: relative;

  .avatar-wrapper {
    width: 92px;
    height: 92px;
  }

  .avatar-img {
    width: 56px;
    height: 56px;
  }

  .badge-img {
    width: 92px;
    height: 92px;
  }

  @media ${deviceBP.sm} {
    .avatar-wrapper {
      width: 48px;
      height: 48px;
    }

    .avatar-img {
      width: 32px;
      height: 32px;
    }

    .badge-img {
      width: 48px;
      height: 48px;
    }
  }
`

export const MobilePointsAvatarContainer = styled(PointsAvatarContainer)`
  margin-right: auto;
`

export const PointsBadge = styled.div`
  position: absolute;
  top: 0px;
  left: 0px;
  /* img { */
  /*   width: 104px; */
  /*   height: 104px; */
  /* } */
`
export const MobilePointsBadge = styled(PointsBadge)`
  img {
    width: 66px;
    height: 66px;
  }
`

export const PointsNameContainer = styled.div`
  margin-top: 6px;
  display: flex;
  flex-direction: column;
  margin-left: 2px;
  /* gap: 8px; */
  .address {
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px;
    margin-bottom: 4px;
  }
`

export const MobilePointsNameContainer = styled(PointsNameContainer)`
  justify-content: flex-start;
  .address {
    font-size: 14px;
    margin-bottom: 7px;
  }
`

export const RankContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
  > span {
    color: rgb(170, 170, 170);
  }
`

export const MobileRankContainer = styled(RankContainer)`
  span {
    font-size: 10px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px; /* 160% */
  }
`

export const TotalPoints = styled.p`
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  @media ${deviceBP.sm} {
    margin-top: 8px;
    margin-bottom: 8px;
  }
`
export const TotalPointsContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 30px;

  @media ${deviceBP.sm} {
    flex: 1;
  }
`
export const PointsCount = styled.div`
  margin-top: -10px;
  span {
    color: #fff;
    text-shadow:
      1px 1px 8.3px rgba(255, 255, 255, 0.5),
      1px 1px 8.3px rgba(255, 255, 255, 0.5);
    font-size: 42px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px;
  }

  @media ${deviceBP.sm} {
    margin-top: -6px;
    span {
      font-size: 28px;
    }
  }
`

export const PointsRightContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  row-gap: 15px;
  column-gap: 25px;

  @media ${deviceBP.lg} {
    button {
      margin-top: 20px;
    }
  }

  @media ${deviceBP.sm} {
    width: 100%;
  }
`

export const MobilePointsRightContainer = styled(PointsRightContainer)`
  grid-template-areas:
    'stats  stats  stats'
    'multiplier multiplier multiplier';
`

export const PointsStatsContainer = styled.div`
  grid-area: stats;
  display: flex;
  justify-content: flex-end;
  column-gap: ${SPACING.lg}px;
`
export const MobilePointsStatsContainer = styled(PointsStatsContainer)`
  flex-direction: column;
  gap: 22.5px;
`

const PointsStats = styled.div`
  height: 100%;
  height: 63px;
  width: 137px;
  position: relative;
  gap: 8px;

  .container {
    display: flex;
    flex-direction: column;
    /* margin-left: 30px; */
    /* margin-top: 20px; */
    margin-left: 12px;
    margin-top: 12px;
    gap: 8px;
  }
  .heading,
  .count {
    color: ${FARE_COLORS.aqua};
    ${FONT_STYLES.md};
    font-style: normal;
    font-weight: 500;
  }
  .count {
    ${FONT_STYLES.xl};
  }
`

const MobilePointsStats = styled(PointsStats)`
  width: 151px;
  height: 24px;

  .container {
    flex-direction: row-reverse;
    align-items: center;
    margin: 0px;
    padding: 11px 16px;
    justify-content: space-between;
  }
  .heading {
    font-size: 10px;
    font-weight: 500;
    line-height: 10.725px;
    text-align: right;
  }
  .count {
    width: fit-content;
    ${FONT_STYLES.md};
  }
`

export const PointsPlus = styled.div`
  position: absolute;
  right: 8px;
  top: 32px;
`

export const MobilePointsPlus = styled(PointsPlus)`
  left: -5px;
  right: auto;
`

export const PointsKillsStatsContainer = styled(PointsStats)`
  ${PointsKillsBorder};

  @media ${deviceBP.sm} {
    display: none;
  }
`

export const MobilePointsKillsStatsContainer = styled(MobilePointsStats)`
  ${MobilePointsKillsBorder};
`

export const PointsAssistsStatsContainer = styled(PointsStats)`
  ${PointsAssistBorder};
  .heading,
  .count {
    /* color: ${COLORS.warning}; */
    color: ${FARE_COLORS.peach};
  }

  @media ${deviceBP.sm} {
    display: none;
  }
`
export const MobilePointsAssistsStatsContainer = styled(MobilePointsStats)`
  ${MobilePointsAssistsBorder};
  .heading,
  .count {
    color: ${FARE_COLORS.peach};
  }
`

export const PointsDeathsStatsContainer = styled(PointsStats)`
  ${PointsDeathsBorder};
  .heading,
  .count {
    color: ${FARE_COLORS.salmon};
  }

  @media ${deviceBP.sm} {
    display: none;
  }
`

export const MobilePointsDeathsStatsContainer = styled(MobilePointsStats)`
  ${MobilePointsDeathsBorder};
  .heading,
  .count {
    color: ${FARE_COLORS.salmon};
  }
`

export const MobilePointsMultiplierStatsContainer = styled(MobilePointsStats)`
  ${MobilePointsMultiplierBorder};
  .multiplierContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 4px;
  }
  .container {
    flex-direction: row;
  }
  .heading,
  .count {
    color: #bbcbd3;
  }
`

export const PointsMultiplierContainer = styled(PointsStats)`
  grid-area: multiplier;
  ${PointsMultiplierBorder};
  .heading,
  .count {
    color: #bbcbd3;
  }
  .multiplierContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 4px;
  }

  @media ${deviceBP.sm} {
    display: none;
  }
`
export const PointsInfoContainer = styled.div`
  grid-area: info;
  display: flex;
  grid-row: 4;
  padding: 8px 11px;
  flex-direction: row;
  border: 1px solid ${BORDER_COLORS.one};
  /* background: #121212; */
  border-radius: 6px;
  gap: 24px;
  margin-top: 0px;

  @media ${deviceBP.lg} {
    grid-row: 4/7;
  }
`

export const PointsInfoColumnWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 24px;

  @media ${deviceBP.sm} {
    width: 100%;
  }
`

export const MobilePointsInfoContainer = styled.div`
  display: flex;
  justify-content: space-between;
  span {
    font-size: 10px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px;
  }
  .count {
    font-size: 20px;
    text-shadow:
      1px 1px 8.3px rgba(255, 255, 255, 0.5),
      1px 1px 8.3px rgba(255, 255, 255, 0.5);
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
  }
  .pointsContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 17px;
  }
  .infoButton {
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    ${floatingContainer};
    width: 27px;
    height: 27px;
    border-radius: 6px;
  }
  img {
    width: 13px;
    height: 13px;
  }
`

export const PointsInfoCalculatedContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  span {
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px;
  }
`

export const PointsBotContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  padding-top: 12px;
  min-height: 0;

  @media ${deviceBP.sm} {
    min-height: 0;
  }
`

export const PointsDownContainer = styled.div`
  gap: 20px;
  display: inline-flex;
  overflow-x: auto;
  align-items: center;
  min-height: 352px;
  @media (max-width: 375px) {
    overflow-x: auto;
  }
  @media (max-width: 992px) {
    min-height: 352px;
  }
  overflow-y: hidden;
  scrollbar-width: auto !important;
  -ms-overflow-style: auto !important;

  &::-webkit-scrollbar {
    display: block;
    width: 1px;
    height: 3px;
  }

  &::-webkit-scrollbar-thumb {
    background: linear-gradient(45deg, rgba(255, 255, 255, 1), rgba(0, 0, 0, 0));
    width: 0px;
    height: 2px;
    border-radius: 5px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background-color: ${TEXT_COLORS.two};
  }

  &::-webkit-scrollbar-track {
    background-color: transparent;
  }
`

export const MobilePointsButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 14px;
  button {
    min-width: 162px;
    width: 100%;
  }

  @media (max-width: 375px) {
    flex-wrap: wrap;
  }
`

export const SShareBtn = styled.button`
  all: unset;
  position: absolute;
  right: 18px;
  top: 18px;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  cursor: pointer;
  img {
    margin-left: 8px;
  }

  @media ${deviceBP.sm} {
    top: 12px;
    right: 10px;
  }
`

export const SPointsExplainerContainer = styled.div`
  flex: 1;
  width: 300px;

  @media ${deviceBP.sm} {
    width: 100%;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    /* width: 400px; */
  }
`
