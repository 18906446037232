import { PageWrapper } from '../style'
import {
  AvatarWrapper,
  MobileSettingLink,
  MobileSettingsTabWrapper,
  NameWrapper,
  SettingLink,
  SettingsContentWrapper,
  SettingsTabWrapper,
  SettingsTitle,
  SettingsWrapper,
} from './style'
import { AnimatePresence, type Variants } from 'framer-motion'
import { TransactionsTab } from './TransactionsTab'
import { AccountTab } from './AccountTab'
import { RandomnessTab } from './RandomnessTab'
import { useIsBreakpoint } from '@/hooks/common/useIsBreakpoint'
import Avatar from '@/components/shared/Avatar'
import { SPACING } from '@/design'
import { shortenString } from '@/utils/text'
import { motion } from 'framer-motion'
import { useLocalStorage } from 'usehooks-ts'
import { TransactionHistory } from './TransactionHistory'
import { useWeb3 } from '@/hooks/useWeb3'
import ConnectWallet from '@/components/shared/Wallet/ConnectWallet'
import useUserData from '@/hooks/useUserData'
import { useUserImageToRender, useUsernameToRender } from '@/utils/userDisplay'
import { MaxValuesTab } from './MaxValues'

enum SettingsPageEnum {
  ACCOUNT = 'Account',
  TRANSACTIONS = 'Wallet',
  // RANDOMNESS = 'Randomness',
  // REFERRALS = 'Referrals',
  MAX_VALUES = 'Max Values',
  TX_HISTORY = 'Transactions',
}

const SSettingsWalletWrapper = styled(motion.div)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
`

const connectWalletVariant: Variants = {
  initial: {
    opacity: 0,
  },
  animate: {
    opacity: 1,
  },
  exit: {
    opacity: 0,
  },
}

const SAccountDisplay = styled.div`
  * {
    margin: 0px;
  }

  ${SettingsTitle} {
    margin-top: 6px;
  }

  ${NameWrapper} {
    margin-top: 4px;
  }
`

const SAvatar = styled(Avatar)`
  width: 56px !important;
  height: 56px !important;
  img {
    height: 44px !important;
    width: 44px !important;
  }
`

const STwitterPic = styled.img`
  height: 56px;
  width: 56px;
  border-radius: 100%;
  margin-right: 16px;
  border: 4px solid transparent;
`

const SettingsPage = () => {
  const { account, isActive } = useWeb3()
  const [selectedTabIndex, setTabIndex] = useLocalStorage<SettingsPageEnum>(
    'selectedSettingsTabIndex',
    SettingsPageEnum.ACCOUNT
  )
  const isMobileScreen = useIsBreakpoint('sm')
  const { username, avatarSeedAddress, isUsingTwitterToDisplay, twitterUsername, twitterImage } =
    useUserData(true)

  const shortAddr = useMemo(() => shortenString(account || '', 6, 4), [account])

  const userImageToRender = useUserImageToRender(
    account || '0x',
    isUsingTwitterToDisplay,
    avatarSeedAddress,
    twitterImage,
    {
      width: '56px',
      height: '56px',
      borderWidth: 4,
      marginRight: SPACING.md,
    },
    {
      height: '56px',
      width: '56px',
      borderRadius: '100%',
      marginRight: '16px',
      marginLeft: '16px',
      border: '4px solid transparent',
    },
    {
      width: '56px',
      height: '56px',
      borderWidth: 4,
      marginRight: SPACING.md,
    },
    {
      height: '56px',
      width: '56px',
      borderRadius: '100%',
      marginRight: '16px',
      marginLeft: '16px',
      border: '4px solid transparent',
    },
    { size: 'lg' }
  )

  const usernameToRender = useUsernameToRender(
    account || '0x',
    isUsingTwitterToDisplay,
    username,
    twitterUsername,
    {},
    {},
    {},
    true
  )

  console.log(account, username, isUsingTwitterToDisplay, twitterImage)

  return (
    <PageWrapper
      transition={{
        duration: 0.5,
        ease: 'easeIn',
      }}
      initial={{
        opacity: 0,
        transform: 'scale(0.95)',
      }}
      animate={{
        opacity: 1,
        transform: 'scale(1)',
      }}
      exit={{
        opacity: 0,
        transform: 'scale(0.95)',
      }}
    >
      <SettingsWrapper isMobileScreen={isMobileScreen}>
        {!isMobileScreen ?
          <SettingsTabWrapper>
            {Object.values(SettingsPageEnum).map(tab => (
              <SettingLink
                key={tab}
                $isActive={selectedTabIndex === tab}
                onClick={() => setTabIndex(tab)}
              >
                <span>{tab}</span>
                <div className='tab-selected-bar'></div>
              </SettingLink>
            ))}
          </SettingsTabWrapper>
        : <MobileSettingsTabWrapper>
            {Object.values(SettingsPageEnum).map(tab => (
              <MobileSettingLink
                key={tab}
                isActive={selectedTabIndex === tab}
                onClick={() => setTabIndex(tab)}
              >
                {tab}
              </MobileSettingLink>
            ))}
          </MobileSettingsTabWrapper>
        }

        <AnimatePresence key={selectedTabIndex}>
          <SettingsContentWrapper>
            {!isActive && (
              <SSettingsWalletWrapper
                variants={connectWalletVariant}
                initial='initial'
                animate='animate'
                exit='exit'
              >
                <h2>Connect to view settings:</h2>
                <ConnectWallet />
              </SSettingsWalletWrapper>
            )}
            {isActive && (
              <>
                <AvatarWrapper>
                  {userImageToRender}
                  <SAccountDisplay>
                    <SettingsTitle>{selectedTabIndex}</SettingsTitle>
                    <NameWrapper className={isUsingTwitterToDisplay ? 'twitter-link' : ''}>
                      {usernameToRender}
                    </NameWrapper>
                    <NameWrapper>{shortAddr}</NameWrapper>
                  </SAccountDisplay>
                </AvatarWrapper>
                <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }}>
                  {selectedTabIndex === SettingsPageEnum.ACCOUNT ?
                    <AccountTab />
                  : null}
                  {selectedTabIndex === SettingsPageEnum.TRANSACTIONS ?
                    <TransactionsTab />
                  : null}
                  {/* {selectedTabIndex === SettingsPageEnum.RANDOMNESS ?
                    <RandomnessTab />
                  : null} */}
                  {/* {selectedTabIndex === SettingsPageEnum.REFERRALS ? */}
                  {/*   <ReferralsTab /> */}
                  {/*   : null} */}
                  {selectedTabIndex === SettingsPageEnum.MAX_VALUES ?
                    <MaxValuesTab />
                  : null}
                  {selectedTabIndex === SettingsPageEnum.TX_HISTORY ?
                    <TransactionHistory />
                  : null}
                </motion.div>
              </>
            )}
          </SettingsContentWrapper>
        </AnimatePresence>
      </SettingsWrapper>
    </PageWrapper>
  )
}
export default SettingsPage
