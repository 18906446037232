import { SVGS } from '@/assets'
import { MENU_COLORS } from '@/design'

interface IRouteLink {
  title: string
  img: string
  to: string
  bgColor: string
  borderColor: string
  alt?: string
}

export const routeLinks: IRouteLink[] = [
  {
    title: 'dice',
    img: SVGS.diceIcon,
    to: '/dice',
    bgColor: MENU_COLORS.dice.two,
    borderColor: MENU_COLORS.dice.one,
  },
  {
    title: 'coin flip',
    img: SVGS.walletIcon,
    to: '/coin-flip',
    bgColor: MENU_COLORS.coinFlip.two,
    borderColor: MENU_COLORS.coinFlip.one,
  },
  {
    title: 'rps',
    img: SVGS.scissorIcon,
    to: '/rps',
    bgColor: MENU_COLORS.rps.two,
    borderColor: MENU_COLORS.rps.one,
  },
  // {
  //   title: 'quests',
  //   img: SVGS.rewardIcon,
  //   to: '/quests',
  //   bgColor: MENU_COLORS.deathmatch.two,
  //   borderColor: MENU_COLORS.deathmatch.one,
  // },
  // {
  //   title: 'deathmatch',
  //   img: SVGS.deathmatchIcon,
  //   to: '/deathmatch',
  //   bgColor: MENU_COLORS.deathmatch.two,
  //   borderColor: MENU_COLORS.deathmatch.one,
  // },
  // {
  //   title: 'leaderboard',
  //   img: SVGS.fareIcon,
  //   to: '/leaderboard',
  //   bgColor: MENU_COLORS.deathmatch.two,
  //   borderColor: MENU_COLORS.deathmatch.one,
  // },
  {
    title: 'deathmatch',
    img: SVGS.deathmatchIcon,
    to: '/deathmatch',
    bgColor: MENU_COLORS.deathmatch.two,
    borderColor: MENU_COLORS.deathmatch.one,
  },
  {
    title: 'settings',
    img: SVGS.settingsIcon,
    to: '/settings',
    bgColor: MENU_COLORS.settings.two,
    borderColor: MENU_COLORS.settings.one,
  },
] as const

interface IMobileRouteLink {
  title: string
  img: string
  to: string
  particleColors: string | string[]
}

export const mobileRouteLinks: IMobileRouteLink[] = [
  {
    title: 'DICE',
    img: SVGS.diceIcon,
    to: '/dice',
    particleColors: MENU_COLORS.dice.one,
  },
  {
    title: 'COIN FLIP',
    img: SVGS.walletIcon,
    to: '/coin-flip',
    particleColors: MENU_COLORS.coinFlip.one,
  },
  {
    title: 'ROCK PAPER SCISSORS',
    img: SVGS.scissorIcon,
    to: '/rps',
    particleColors: MENU_COLORS.rps.one,
  },
  // {
  //   title: 'QUESTS',
  //   img: SVGS.rewardIcon,
  //   to: '/quests',
  //   particleColors: MENU_COLORS.deathmatch.one,
  // },
  {
    title: 'DEATHMATCH',
    img: SVGS.deathmatchIcon,
    to: '/deathmatch',
    particleColors: MENU_COLORS.deathmatch.one,
  },
  // {
  //   title: 'LEADERBOARD',
  //   img: SVGS.fareIcon,
  //   to: '/leaderboard',
  //   particleColors: MENU_COLORS.deathmatch.one,
  // },
  {
    title: 'SETTINGS',
    img: SVGS.settingsIcon,
    to: '/settings',
    particleColors: '#555555',
  },
]

export const externalLinks: Omit<IRouteLink, 'bgColor' | 'borderColor'>[] = [
  {
    title: 'DISCORD',
    img: SVGS.discordIcon,
    to: 'https://discord.com/invite/eUEwY3vS8R',
    alt: 'discord',
  },
  {
    title: 'X (TWITTER)',
    img: SVGS.xIcon,
    to: 'https://www.x.com/fareplayio',
    alt: 'x',
  },
]
