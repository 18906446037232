import { MotionConfig, type Transition } from 'framer-motion'
import { Canvas, extend } from '@react-three/fiber'
import { MotionCanvas } from 'framer-motion-3d'

import { BREAKPOINTS, BORDER_COLORS, COMPONENTS } from '@/design'
import { CoinScene } from './CoinScene'
import { fareCoinFlipAddress, GameNames } from '@/lib/crypto'
import { DeltaNumbersDisplay } from '@/components/DeltaNumbersDisplay'
// import { GameNoti } from '@/components/shared/GameNoti'

// @TODO: Check if this is needed or not
extend({ MotionCanvas })

const SCoinCanvas = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 6px;
  border: 1px solid ${BORDER_COLORS.one};
  backdrop-filter: blur(2px);
  grid-column: 1 / span 5;
  position: relative;
  overflow: hidden;

  /* Vendor prefixes */
  -webkit-backdrop-filter: blur(2px); /* Safari and Chrome */
  -moz-backdrop-filter: blur(2px); /* Firefox */
  -ms-backdrop-filter: blur(2px); /* Edge */

  @media only screen and (max-width: ${BREAKPOINTS.sm}px) {
    border: none;
    height: ${COMPONENTS.mobileGameView}px;

    backdrop-filter: blur(0px);
    /* Vendor prefixes */
    -webkit-backdrop-filter: blur(0px); /* Safari and Chrome */
    -moz-backdrop-filter: blur(0px); /* Firefox */
    -ms-backdrop-filter: blur(0px); /* Edge */
  }
`

const defaultTransition: Transition = {
  type: 'tween',
  ease: 'linear',
  duration: 0.35,
}

export const CoinFlipCanvas = () => {
  // useDidIRender('Coin3D')

  return (
    <SCoinCanvas>
      <DeltaNumbersDisplay gameName={GameNames.CoinFlip} />
      <MotionConfig transition={defaultTransition}>
        <MotionCanvas
          gl={{
            antialias: true,
            alpha: true,
            stencil: false,
            depth: true,
            // toneMapping: NoToneMapping,
          }}
          dpr={[1, 2]}
          // linear
        >
          <fog attach='fog' args={[0x000000, 5, 65]} />

          {/* <OrbitControls /> */}
          <CoinScene />
        </MotionCanvas>
        <Canvas style={{ display: 'none' }}>
          <group />
        </Canvas>
      </MotionConfig>
    </SCoinCanvas>
  )
}
