import type { Web3Provider, WebSocketProvider } from '@ethersproject/providers'
import { parseEther } from 'viem'
import {
  FareAA__factory,
  // IERC20Metadata__factory,
  // FareBankroll__factory,
  // @TODO: This is to allow others to test, should remove this in future
  USDCMock__factory,
  UsdcVault__factory,
} from './typechain'
import { diceHelperFunctions } from './dice'
import { coinFlipHelperFunctions } from './coinFlip'
import { rpsHelperFunctions } from './rps'
import { SVGS } from '@/assets'

export const {
  VITE_ZERO_DEV_PROJECT_ID,
  VITE_FARE_COIN_FLIP_ADDRESS,
  VITE_FARE_DICE_ADDRESS,
  VITE_FARE_RPS_ADDRESS,
  VITE_USDC_ADDRESS,
  VITE_FARE_BANKROLL_ADDRESS,
  VITE_FARE_CREDIT_ADDRESS,
  VITE_FARE_AA_ADDRESS,
  VITE_USDC_VAULT_ADDRESS,
} = import.meta.env

export const fareCoinFlipAddress = VITE_USDC_VAULT_ADDRESS
export const fareDiceAddress = VITE_USDC_VAULT_ADDRESS
export const fareRPSAddress = VITE_USDC_VAULT_ADDRESS
export const usdcAddress = VITE_USDC_ADDRESS
export const fareBankrollAddress = VITE_FARE_AA_ADDRESS
export const ZERO_ADDRESS = '0x0000000000000000000000000000000000000000'
export const UINT256_MAX_VALUE =
  '115792089237316195423570985008687907853269984665640564039457584007913129639935'

export const decimals = 18
export const unit = parseEther('1')

export function getSUContract(contractAddress: string, provider?: Web3Provider) {
  if (!provider) return
  return UsdcVault__factory.connect(contractAddress, provider.getSigner())
}

export function getUsdcContract(provider?: Web3Provider | WebSocketProvider) {
  if (!provider) return
  return USDCMock__factory.connect(usdcAddress, provider.getSigner())
}

export function getBankrollContract(contractAddress: string, provider?: Web3Provider) {
  if (!provider) return
  return FareAA__factory.connect(contractAddress, provider.getSigner())
}

// @TODO: This is to allow others to test, should remove this in future
export function getMockUsdcContract(provider?: Web3Provider | WebSocketProvider) {
  if (!provider) return
  return USDCMock__factory.connect(usdcAddress, provider.getSigner())
}

export const SUContractInterface = UsdcVault__factory.createInterface()
export const USDCContractInterface = USDCMock__factory.createInterface()
export const BankrollContractInterface = FareAA__factory.createInterface()

export type GameAddressMap = {
  coinFlip: string
  dice: string
  rps: string
}

export type GameType = keyof GameAddressMap

export enum GameNames {
  CoinFlip = 'coinFlip',
  RPS = 'rps',
  Dice = 'dice',
}

export const GameNameToQK = {
  coinFlip: {
    getQK: (side: number) => ({ q: [parseEther('0.5')], k: [parseEther('1.98')] }),
  },
  rps: {
    getQK: (side: number) => ({
      q: [unit / 3n, unit / 3n],
      k: [parseEther('1.98'), parseEther('0.99')],
    }),
  },
  dice: {
    getQK: (side: number) => ({
      q: [((10000n - BigInt(side)) * unit) / 10000n],
      k: [(((10000n * unit) / (10000n - BigInt(side))) * 99n * unit) / 100n / unit],
    }),
  },
}

export const gameAddressMap: GameAddressMap = {
  coinFlip: VITE_FARE_COIN_FLIP_ADDRESS,
  dice: VITE_FARE_DICE_ADDRESS,
  rps: VITE_FARE_RPS_ADDRESS,
}

export const gameNameToSVGMap = {
  [GameNames.CoinFlip]: SVGS.walletIcon,
  [GameNames.Dice]: SVGS.diceIcon,
  [GameNames.RPS]: SVGS.scissorIcon,
}

export const addressGameMap: { [key: string]: GameType } = {
  [VITE_FARE_COIN_FLIP_ADDRESS]: 'coinFlip',
  [VITE_FARE_DICE_ADDRESS]: 'dice',
  [VITE_FARE_RPS_ADDRESS]: 'rps',
}

export type CurrencyAddressMap = {
  usdc: string
  fare: string
  eth: string
}

export type CurrencyType = keyof CurrencyAddressMap

export const currencyAddressMap: CurrencyAddressMap = {
  usdc: VITE_USDC_ADDRESS,
  fare: '0x00',
  eth: '0x00',
}

export const currencyDecimalsMap: { [K in CurrencyType]: number } = {
  eth: 18,
  fare: 18,
  usdc: 6,
}

export function getGameAddressFromRoute(route: string) {
  if (route === '/dice') {
    return gameAddressMap.dice
  } else if (route === '/rps') {
    return gameAddressMap.rps
  } else if (route === '/coin-flip') {
    return gameAddressMap.coinFlip
  } else {
    return null
  }
}

export const routeGameTypeMap: { [key: string]: GameType } = {
  '/dice': 'dice',
  '/rps': 'rps',
  '/coin-flip': 'coinFlip',
}

export type HelperFunctions = { isValidSide: (side: number) => boolean } & (
  | HelperFunctionsWithSide
  | HelperFunctionsWithoutSide
)

export type HelperFunctionsWithSide = {
  getMultiplierWithoutPPVFromSide: (side: number) => number
  getMultiplierWithPPVFromSide: (side: number) => number
  getKellyFractionFromSide: (side: number) => number
  getPotentialProfitCoefficientFromSide: (side: number) => number
}

export type HelperFunctionsWithoutSide = {
  getMultiplierWithoutPPV: () => number
  getMultiplierWithPPV: () => number
  getKellyFraction: () => number
  getPotentialProfitCoefficient: () => number
}

export enum HelperFunctionNames {
  getMultiplierWithPPV = 'getMultiplierWithPPV',
  getMultiplierWithoutPPV = 'getMultiplierWithoutPPV',
  getKellyFraction = 'getKellyFraction',
  getPotentialProfitCoefficient = 'getPotentialProfitCoefficient',
}

export enum HelperFunctionNamesWithSide {
  getMultiplierWithPPV = 'getMultiplierWithPPVFromSide',
  getMultiplierWithoutPPV = 'getMultiplierWithoutPPVFromSide',
  getKellyFraction = 'getKellyFractionFromSide',
  getPotentialProfitCoefficient = 'getPotentialProfitCoefficientFromSide',
}

export type HelperFunctionName =
  | 'getMultiplierWithPPV'
  | 'getMultiplierWithoutPPV'
  | 'getKellyFraction'
  | 'getPotentialProfitCoefficient'

export const isValidSide = (gameName: GameNames, side: number) => {
  const isWithSide = gameTypeIsHelperFunctionsWithSideMap[gameName]
  if (isWithSide) {
    return (gameTypeHelperFunctionsMap[gameName] as HelperFunctions).isValidSide(side)
  } else {
    return (gameTypeHelperFunctionsMap[gameName] as HelperFunctions).isValidSide(side)
  }
}

export const getMultiplierWithPPV = (gameName: GameNames, side?: number) => {
  return helperFunctionWrapper(HelperFunctionNames.getMultiplierWithPPV, gameName, side)
}

export const getMultiplierWithoutPPV = (gameName: GameNames, side?: number) => {
  return helperFunctionWrapper(HelperFunctionNames.getMultiplierWithoutPPV, gameName, side)
}

export const getKellyFraction = (gameName: GameNames, side?: number) => {
  return helperFunctionWrapper(HelperFunctionNames.getKellyFraction, gameName, side)
}

export const getPotentialProfitCoefficient = (gameName: GameNames, side?: number) => {
  return helperFunctionWrapper(HelperFunctionNames.getPotentialProfitCoefficient, gameName, side)
}

// routeGameTypeMap[pathname] as GameNames
const helperFunctionWrapper = (
  functionName: HelperFunctionName,
  gameName: GameNames,
  side?: number
) => {
  // const gameType = addressGameMap[contractAddress]
  const isWithSide = gameTypeIsHelperFunctionsWithSideMap[gameName]
  if (isWithSide) {
    if (side) {
      return (gameTypeHelperFunctionsMap[gameName] as HelperFunctionsWithSide)[
        HelperFunctionNamesWithSide[functionName]
      ](side)
    } else {
      throw `Have to provide side to call ${functionName}`
    }
  } else {
    return (gameTypeHelperFunctionsMap[gameName] as HelperFunctionsWithoutSide)[functionName]()
  }
}

export const gameTypeHelperFunctionsMap = {
  dice: diceHelperFunctions,
  rps: rpsHelperFunctions,
  coinFlip: coinFlipHelperFunctions,
}

export const gameTypeIsHelperFunctionsWithSideMap = {
  dice: true,
  rps: false,
  coinFlip: false,
}

export const gameContractAddresses = [
  VITE_FARE_COIN_FLIP_ADDRESS,
  VITE_FARE_RPS_ADDRESS,
  VITE_FARE_DICE_ADDRESS,
]
