import { Input } from '@/components/shared/Input'
import { SAccountInfoBox, SAccountInfoWrapper, SInputWrapper } from './AccountTab'
import { useIsBreakpoint } from '@/hooks/common/useIsBreakpoint'
import { InfoWrapper, SettingsSubtitle } from './style'
import { Button, ButtonEnum } from '@/components/shared/Button'
import useMaxValuesStore from '@/store/useMaxValuesStore'
import { useWeb3 } from '@/hooks/useWeb3'
import useMaxValues from '@/hooks/useMaxValues'

export const MaxValuesTab = () => {
  const {
    ethUsdcPriceSCValue,
    averageCallbackGasSCValue,
    aaCostMultiplierSCValue,
    ethUsdcPriceBufferPercentage,
    averageCallbackGasBufferPercentage,
    aaCostMultiplierBufferPercentage,
    setEthUsdcPriceBufferPercentage,
    setAverageCallbackGasBufferPercentage,
    setAACostMultiplierBufferPercentage,
  } = useMaxValuesStore()
  const { fetchAndSetSCMaxValues, fetchSCMaxValues } = useMaxValues()

  const { account } = useWeb3()
  const isMobileScreen = useIsBreakpoint('sm')

  // @TODO: Should show the smart contract value we are applying the buffer to (basically the smart contract value at the time when user set it) (100k value for below example)
  // @TODO: Should show the end result for the value, so for example, with above comment, we are showing when the buffer was applied smart contract had 100k for that value and with user's set of 10% current value is 110k. We should show this 110k as well as 100k

  // @NOTE: ETH/USDC price
  const [editingEthUsdcPriceBuffer, setEditingEthUsdcPriceBuffer] = useState('')
  const [isEditingEthUsdcPriceBuffer, setIsEditingEthUsdcPriceBuffer] = useState(false)
  const saveEthUsdcPriceBuffer = async () => {
    if (!account) return
    setEthUsdcPriceBufferPercentage(editingEthUsdcPriceBuffer, account)
    setIsEditingEthUsdcPriceBuffer(false)
  }

  // @NOTE: Average callback gas
  const [editingAverageCallbackGas, setEditingAverageCallbackGas] = useState('')
  const [isEditingAverageCallbackGas, setIsEditingAverageCallbackGas] = useState(false)
  const saveAverageCallbackGas = async () => {
    if (!account) return
    setAverageCallbackGasBufferPercentage(editingAverageCallbackGas, account)
    setIsEditingAverageCallbackGas(false)
  }

  // @NOTE: aa cost multiplier
  const [editingAACostMultiplier, setEditingAACostMultiplier] = useState('')
  const [isEditingAACostMultiplier, setIsEditingAACostMultiplier] = useState(false)
  const saveAACostMultiplier = async () => {
    if (!account) return
    setAACostMultiplierBufferPercentage(editingAACostMultiplier, account)
    setIsEditingAACostMultiplier(false)
  }
  const [ethUsdcPriceCurrentSCValue, setEthUsdcPriceCurrentSCValue] = useState('')
  const [averageCallbackGasCurrentSCValue, setAverageCallbackGasCurrentSCValue] = useState('')
  const [aaCostMultiplierCurrentSCValue, setAACostMultiplierCurrentSCValue] = useState('')

  const refetchSCMaxValues = async () => {
    // @TODO: Refetch values and set local storage
    if (!account) return
    await fetchAndSetSCMaxValues(account)
  }

  const fetchAndSetCurrentSCValues = useCallback(async () => {
    const scValues = await fetchSCMaxValues()
    if (!scValues) return
    setEthUsdcPriceCurrentSCValue(scValues.ethUsdcPriceSCValue)
    setAverageCallbackGasCurrentSCValue(scValues.averageCallbackGasSCValue)
    setAACostMultiplierCurrentSCValue(scValues.aaCostMultiplierSCValue)
  }, [fetchSCMaxValues])

  useEffect(() => {
    fetchAndSetCurrentSCValues()
  }, [fetchAndSetCurrentSCValues])

  return (
    <div>
      <SettingsSubtitle>Set your max values for protection</SettingsSubtitle>
      <InfoWrapper className='account-info-wrapper'>
        <SAccountInfoWrapper $isMobileScreen={isMobileScreen}>
          <span>ETH / USDC price:</span>
          <span>
            Your base value: {ethUsdcPriceSCValue} ::: Smart Contract value:{' '}
            {ethUsdcPriceCurrentSCValue}
          </span>
          <span className='account-info-label'>Buffer %</span>
          <SAccountInfoBox>
            {isEditingEthUsdcPriceBuffer ?
              <>
                <SInputWrapper>
                  <Input
                    className='account-input-field'
                    name='fp-input-1'
                    autoFocus
                    placeholder='Enter buffer percentage...'
                    value={editingEthUsdcPriceBuffer}
                    onChange={ev => setEditingEthUsdcPriceBuffer(ev.currentTarget.value)}
                  />
                </SInputWrapper>
                <Button
                  key='1'
                  className='cancel-btn'
                  isMinified
                  disabled={false}
                  isLoading={false}
                  buttonType={ButtonEnum.EDIT_1}
                  onClick={() => setIsEditingEthUsdcPriceBuffer(false)}
                >
                  CANCEL
                </Button>
                <Button
                  key='2'
                  className={'save-btn'}
                  isMinified
                  disabled={false}
                  isLoading={false}
                  buttonType={ButtonEnum.EDIT_1}
                  onClick={saveEthUsdcPriceBuffer}
                >
                  SAVE
                </Button>
              </>
            : <>
                <span className='account-info-value'>
                  {ethUsdcPriceBufferPercentage || 'DEFAULT TO 10%'}
                </span>
                <Button
                  key='3'
                  isMinified
                  disabled={false}
                  isLoading={false}
                  buttonType={ButtonEnum.EDIT_1}
                  onClick={() => setIsEditingEthUsdcPriceBuffer(true)}
                >
                  {ethUsdcPriceBufferPercentage ? 'EDIT' : 'ADD'}
                </Button>
              </>
            }
          </SAccountInfoBox>
        </SAccountInfoWrapper>
        <SAccountInfoWrapper $isMobileScreen={isMobileScreen}>
          <span>Average Callback Gas:</span>
          <span>
            Your base value: {averageCallbackGasSCValue} ::: Smart Contract value:{' '}
            {averageCallbackGasCurrentSCValue}
          </span>
          <span className='account-info-label'>Buffer %</span>
          <SAccountInfoBox>
            {isEditingAverageCallbackGas ?
              <>
                <SInputWrapper>
                  <Input
                    className='account-input-field'
                    name='fp-input-1'
                    autoFocus
                    placeholder='Enter buffer percentage...'
                    value={editingAverageCallbackGas}
                    onChange={ev => setEditingAverageCallbackGas(ev.currentTarget.value)}
                  />
                </SInputWrapper>
                <Button
                  key='1'
                  className='cancel-btn'
                  isMinified
                  disabled={false}
                  isLoading={false}
                  buttonType={ButtonEnum.EDIT_1}
                  onClick={() => setIsEditingAverageCallbackGas(false)}
                >
                  CANCEL
                </Button>
                <Button
                  key='2'
                  className={'save-btn'}
                  isMinified
                  disabled={false}
                  isLoading={false}
                  buttonType={ButtonEnum.EDIT_1}
                  onClick={saveAverageCallbackGas}
                >
                  SAVE
                </Button>
              </>
            : <>
                <span className='account-info-value'>
                  {averageCallbackGasBufferPercentage || 'DEFAULT TO 10%'}
                </span>
                <Button
                  key='3'
                  isMinified
                  disabled={false}
                  isLoading={false}
                  buttonType={ButtonEnum.EDIT_1}
                  onClick={() => setIsEditingAverageCallbackGas(true)}
                >
                  {averageCallbackGasBufferPercentage ? 'EDIT' : 'ADD'}
                </Button>
              </>
            }
          </SAccountInfoBox>
        </SAccountInfoWrapper>
        <SAccountInfoWrapper $isMobileScreen={isMobileScreen}>
          <span>AA cost multiplier:</span>
          <span>
            Your base value: {aaCostMultiplierSCValue} ::: Smart Contract value:{' '}
            {aaCostMultiplierCurrentSCValue}
          </span>
          <span className='account-info-label'>Buffer %</span>
          <SAccountInfoBox>
            {isEditingAACostMultiplier ?
              <>
                <SInputWrapper>
                  <Input
                    className='account-input-field'
                    name='fp-input-1'
                    autoFocus
                    placeholder='Enter buffer percentage...'
                    value={editingAACostMultiplier}
                    onChange={ev => setEditingAACostMultiplier(ev.currentTarget.value)}
                  />
                </SInputWrapper>
                <Button
                  key='1'
                  className='cancel-btn'
                  isMinified
                  disabled={false}
                  isLoading={false}
                  buttonType={ButtonEnum.EDIT_1}
                  onClick={() => setIsEditingAACostMultiplier(false)}
                >
                  CANCEL
                </Button>
                <Button
                  key='2'
                  className={'save-btn'}
                  isMinified
                  disabled={false}
                  isLoading={false}
                  buttonType={ButtonEnum.EDIT_1}
                  onClick={saveAACostMultiplier}
                >
                  SAVE
                </Button>
              </>
            : <>
                <span className='account-info-value'>
                  {aaCostMultiplierBufferPercentage || 'DEFAULT TO 10%'}
                </span>
                <Button
                  key='3'
                  isMinified
                  disabled={false}
                  isLoading={false}
                  buttonType={ButtonEnum.EDIT_1}
                  onClick={() => setIsEditingAACostMultiplier(true)}
                >
                  {aaCostMultiplierBufferPercentage ? 'EDIT' : 'ADD'}
                </Button>
              </>
            }
          </SAccountInfoBox>
        </SAccountInfoWrapper>
        <SAccountInfoWrapper $isMobileScreen={isMobileScreen}>
          <span className='account-info-label'>
            Buffers are applied to the smart contract values at the time of you set them
          </span>
          <SAccountInfoBox>
            <Button
              key='3'
              isMinified
              disabled={false}
              isLoading={false}
              buttonType={ButtonEnum.EDIT_1}
              onClick={refetchSCMaxValues}
            >
              {/* {aaCostMultiplierBufferPercentage ? 'EDIT' : 'ADD'} */}
              Re apply buffers to up to date values
            </Button>
          </SAccountInfoBox>
        </SAccountInfoWrapper>
      </InfoWrapper>
    </div>
  )
}
