import { type PropsWithChildren } from 'react'

import { type GameModes } from '@/components/shared/Spinner'
import { FloatingContainer, PageWrapper } from './style'

interface IGamePageProps extends PropsWithChildren {
  mode: GameModes
}

export const GamePage = ({ children, mode: _mode }: IGamePageProps) => {
  return (
    <>
      <PageWrapper>
        <FloatingContainer
          transition={{
            duration: 0.5,
            ease: 'easeIn',
          }}
          initial={{
            opacity: 0,
            // transform: 'scale(0.95)', // @TODO: Animate scaling causes issue with game canvases
          }}
          animate={{
            opacity: 1,
            // transform: 'scale(1)',
          }}
          exit={{
            opacity: 0,
            // transform: 'scale(0.95)',
          }}
        >
          {children}
        </FloatingContainer>
      </PageWrapper>
    </>
  )
}
