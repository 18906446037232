interface IDiceModel {
  meshProps?: JSX.IntrinsicElements['mesh']
  boxGeometryProps?: JSX.IntrinsicElements['boxGeometry']
}

export const DiceModel = forwardRef<any, IDiceModel>(
  ({ meshProps = {}, boxGeometryProps = {} }, ref: any) => {
    return (
      <mesh ref={ref?.diceMeshRef} {...meshProps}>
        <boxGeometry args={[0.5, 0.5, 0.5, 10, 10, 10]} {...boxGeometryProps} />
        <meshPhysicalMaterial
          ref={ref?.diceMatRef}
          color={[1, 1, 1]}
          toneMapped={true}
          emissiveIntensity={0}
          transparent
        />
      </mesh>
    )
  }
)
