import { lerp } from 'three/src/math/MathUtils'
import AmountSlider from '../shared/Slider/AmountSlider'
import { DiceContainer, MarkContainer, SliderContainer, SliderThumb } from './styled'

const Thumb = (props: any, _state: any) => {
  return <SliderThumb {...props} />
}

interface DiceSliderProps {
  sliderValue?: number
  setSliderValue?: (value: number) => void
}

export const DiceSlider = ({ sliderValue, setSliderValue }: DiceSliderProps) => {
  const [val, setVal] = useState(52)
  const getNormalizedValueRange = (value: number) => {
    const totalRange = 94.9
    const normalizedValue = Number((value - 5).toFixed(2))
    const percentage = normalizedValue / totalRange
    let newValue = 0
    if (percentage <= 0.5) {
      newValue = Math.round(lerp(5, 50, percentage * 2))
    } else {
      newValue = Math.round(lerp(50, 99.9, (percentage - 0.5) * 2))
    }
    return Math.min(99.9, newValue)
  }

  const handleChange = useCallback(
    (newValue: number) => {
      const interpolatedValue = getNormalizedValueRange(newValue)

      // Ensuring the value is within the range and rounded to the nearest integer
      // const clampedValue = Math.min(99.9, Math.max(5, Math.round(newValue)))
      setSliderValue?.(interpolatedValue)
      setVal(newValue)
      return interpolatedValue
    },
    [setSliderValue]
  )

  const getInverseNormalizedValueRange = (interpolatedValue: number) => {
    if (interpolatedValue > 90) return interpolatedValue
    return interpolatedValue - 1.5
  }

  const handleInverseChange = useCallback(
    (interpolatedValue: number) => {
      const newValue = getInverseNormalizedValueRange(interpolatedValue)

      // Ensuring the value is within the range and rounded to the nearest integer
      // const clampedValue = Math.min(99.9, Math.max(5, Math.round(newValue)))
      // setSliderValue?.(interpolatedValue)
      // setVal(newValue)
      return newValue
    },
    [setSliderValue]
  )

  const Mark = ({ key }: any) => {
    const marks = [5, 99.9]
    if (marks.includes(key)) {
      let label = 'WIN'
      let posLeft = 0

      if (key === 5) {
        posLeft = 0
        label = 'LOSE'
      } else if (key === 99.9) {
        posLeft = 100
        label = 'WIN'
      }

      return (
        <MarkContainer className={label.toLowerCase()} key={key} left={posLeft}>
          {label}
        </MarkContainer>
      )
    }
    return null
  }

  useEffect(() => {
    const newValue = handleInverseChange(sliderValue || 52)
    setVal(newValue)
  }, [sliderValue])

  return (
    <SliderContainer>
      <DiceContainer className='slider-input-wrapper'>
        <AmountSlider
          className='slider-z-index'
          marks={[5, 50, 99.9]}
          renderMark={Mark}
          max={100}
          min={5}
          step={1}
          value={val}
          // defaultValue={52}
          onChange={handleChange}
          renderThumb={Thumb}
        />
      </DiceContainer>
    </SliderContainer>
  )
}
