import useAuth from '@/hooks/useAuth'
import { Navigate } from 'react-router-dom'
// import { Redirect } from '../Routing/Redirect'
import { useEffectOnce } from 'react-use'
import { useDebouncedCallback } from 'use-debounce'
import { addAppNoti } from '@/store/useNotiStore'

const LinkTwitter = () => {
  const { authApi } = useAuth()
  const [loading, setLoading] = useState(true)
  // use useEffect and send a request to backend with both twitter session and auth token to verify both twitter and metamask account
  // after that redirect to coin flip
  // @TODO: Works as expected if it successfully links twitter account with publicAddress. But does not handle errors yet
  useEffectOnce(() => {
    linkTwitter()
  })

  const linkTwitter = useDebouncedCallback(
    async () => {
      try {
        await authApi.linkTwitter()
        addAppNoti({
          msg: `Twitter connected`,
          type: 'success',
        })
      } catch (err) {
        if ((err as unknown as any).response.data.message) {
          addAppNoti({
            msg: `${(err as any).response.data.message}`,
            type: 'error',
          })
          throw new Error(`Error linking twitter`)
        } else {
          addAppNoti({
            msg: `Failed: twitter connection`,
            type: 'error',
          })
          throw new Error(`Error linking twitter no twitter connection`)
        }
      } finally {
        setLoading(false)
      }
    },
    250,
    { leading: true }
  )

  return loading ? <div>linking twitter...</div> : <Navigate to='/settings' replace />
}

export default LinkTwitter
