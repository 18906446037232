import { FARE_COLORS, SPACING, TEXT_COLORS } from '@/design'
// import { Prefix } from '../../Input'

export const SwapWrapper = styled.div`
  padding: ${SPACING.md}px;
`
export const Title = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: ${SPACING.xs}px;
  text-transform: uppercase;
`

export const SwapInputWrapper = styled.div`
  &:not(:last-of-type) {
    margin-bottom: ${SPACING.md}px;
  }

  //   button {
  //     * {
  //       padding: ${SPACING.sm}px;
  //       font-size: 32px;
  //       text-transform: uppercase;
  //     }
  //   }

  input {
    width: 100%;
    height: 36px;
    font-size: 32px;
    padding-left: ${SPACING.xxl + SPACING.sm}px !important;
  }
`

const imgFade = keyframes`
    0% {
        opacity: 100%;
    }
    
    50% {
        opacity: 0%;
    }
    
    100% {
        opacity: 100%;
    }
    
`

export const LoadingWrapper = styled.div`
  display: grid;
  padding: ${SPACING.lg}px;
  grid-template-columns: 1fr 200px 1fr;

  > * {
    margin: auto;
  }

  > img {
    height: 36px;
    width: 36px;

    // &:first-of-type {
    //   animation: 2s ${imgFade} infinite;
    // }

    // &:last-of-type {
    //   animation: 2s ${imgFade} infinite;
    //   animation-delay: 1s;
    // }
  }
`

export const SpinnerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: auto;
  color: ${TEXT_COLORS.two};

  > * {
    margin: auto;
  }

  > div:not(:first-of-type) {
    margin-top: ${SPACING.md}px;
  }
`

const gradientKeyframe = keyframes`
  0% {
    background-position: 0% 50%
  }

  50% {
    background-position: 100% 50%
  }
  
  100% {
    background-position: 0% 50%
  }
`

export const FARE_GRADIENT = css`
  background: linear-gradient(to right, ${FARE_COLORS.aqua}, ${FARE_COLORS.peach});
  background-size: 400% 400%;

  -webkit-animation: ${gradientKeyframe} 2s ease infinite;
  -moz-animation: ${gradientKeyframe} 2s ease infinite;
  animation: ${gradientKeyframe} 2s ease infinite;
`

export const QuickplayStrip = styled.div`
  width: 80px;
  height: 2px;
  border-radius: 12px;
  ${FARE_GRADIENT};
  margin-bottom: ${SPACING.sm}px;
`
