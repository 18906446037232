import { SVGS } from '@/assets'
import { HeaderStrip, HeaderWrapper, MainHeader, RightHeader, UserBalanceWrapper } from './style'
import useCurrencyStore from '@/store/useCurrencyStore'
import CountUp from 'react-countup'
import { Bar, MenuSandwich } from '../MobileMenu/style'
import Avatar from '../Avatar'
import { useWeb3 } from '@/hooks/useWeb3'

interface IMobileHeaderProps {
  setShowMenu: (show?: boolean) => void
  isShowMenu: boolean

  setShowProfile: (show?: boolean) => void
  isShowProfile: boolean
}

export const MobileHeader = (props: IMobileHeaderProps) => {
  return (
    <HeaderWrapper>
      <MainHeader>
        <LeftMainHeader {...props} />
        <UserBalance />
        <RightMainHeader {...props} />
      </MainHeader>
      <HeaderStrip />
    </HeaderWrapper>
  )
}

const LeftMainHeader = ({
  setShowProfile,
  isShowProfile,
}: Omit<IMobileHeaderProps, 'setShowMenu' | 'isShowMenu'>) => {
  const { account } = useWeb3()
  return (
    <div onClick={() => setShowProfile()}>
      <Avatar size="sm" seed={account || ''} />
    </div>
  )
}

const RightMainHeader = ({
  setShowMenu,
  isShowMenu,
}: Omit<IMobileHeaderProps, 'setShowProfile' | 'isShowProfile'>) => {
  const menuContent = useMemo(() => {
    return (
      <MenuSandwich onClick={() => setShowMenu()}>
        <Bar isActive={isShowMenu}></Bar>
        <Bar isActive={isShowMenu}></Bar>
        <Bar isActive={isShowMenu}></Bar>
      </MenuSandwich>
    )
  }, [isShowMenu, setShowMenu])

  return <RightHeader>{menuContent}</RightHeader>
}

const UserBalance = () => {
  const balances = useCurrencyStore((state) => state.balances)
  const selectedCurrencyAmount = balances.usdc

  return (
    <UserBalanceWrapper>
      <img src={SVGS.usdcIcon} alt="fare" />
      <CountUp
        end={Number(selectedCurrencyAmount)}
        decimals={2}
        duration={2}
        separator={','}
        preserveValue
      />
    </UserBalanceWrapper>
  )
}
