import { useWeb3 } from '@/hooks/useWeb3'

import useAuth from '@/hooks/useAuth'
import { Button, ButtonEnum } from '../Button'

const SAuthButton = styled(Button)`
  margin-right: 16px !important;
`

const AuthWallet = ({ ...props }) => {
  const { isActive } = useWeb3()
  const { authToken, authenticate, isAuthed, isAuthing } = useAuth()

  if (!isActive || authToken) return null

  return (
    <SAuthButton
      type='button'
      buttonType={ButtonEnum.CONNECT_WALLET}
      disabled={isAuthing}
      onClick={authenticate}
      isLoading={isAuthing}
      loadingText={'AUTHENTICATING'}
      {...props}
    >
      Authenticate
    </SAuthButton>
  )
}

export default AuthWallet
