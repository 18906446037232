import { useFrame } from '@react-three/fiber'
import numeral from 'numeral'
import { DiceModel } from './DiceModel'
import { Clock, Color, Mesh, MeshPhysicalMaterial } from 'three'
import { lerp } from 'three/src/math/MathUtils'
import { easeOutSine } from '@/utils/3d'
import { easeIn } from 'framer-motion'
import { DiceNumber, DiceNumberProps } from './DiceNumber'
import { useDiceGameState } from '@/store/useGameStateStore'
import { entryEvent } from '@/events/entryEvent'

export interface IMultiDice {
  amount: number
  multiDiceState: 'initial' | 'start' | 'resolve'
  resetDice: () => void
}

const DICE_START_Y = 0.2
const DICE_END_Y = 3
const ZINK_DELAY = 0.2
const ZINK_TO_TOP_TIME = 0.6
const NEXT_BATCH_DELAY = 1

export const MultiDice = ({ amount, multiDiceState, resetDice }: IMultiDice) => {
  /* Refs */
  const diceMeshRefs = useRef<Array<Mesh>>([])
  const diceMatRefs = useRef<Array<any>>([])
  const textRefs = useRef<Array<DiceNumberProps>>([])
  const isResolved = useRef<Array<boolean>>(Array(amount).fill(false))
  const animClock = useRef<Clock>(new Clock(false))
  const currentBatchIdx = useRef<number>(0)
  const selectedSide = useRef(50.5)
  const resultSides = useRef<number[]>([])
  const resultRewards = useRef<number[]>([])
  const submittedAmount = useRef(0)
  const submittedCount = useRef(0)
  const playedCount = useRef(0)

  /* Memos */
  const winEmissionColor = useMemo(() => new Color(0.1, 1, 0.1), [])
  const loseEmissionColor = useMemo(() => new Color(1, 0.1, 0.1), [])
  const winColor = useMemo(() => new Color(0.29, 0.961, 0.83), [])
  const loseColor = useMemo(() => new Color(0.85, 0.0, 0.84), [])
  const whiteColor = useMemo(() => new Color(1, 1, 1), [])

  const DiceModels = useMemo(() => {
    const models: any[] = []

    new Array(amount).fill(null).forEach((_val, idx) => {
      const columnIdx = idx % 5
      const rowIdx = Math.floor(idx / 5)
      const xPos = -2.35 + columnIdx / 0.85
      const yPos = DICE_END_Y
      const zPos = 1
      // const zPos = 1 + rowIdx * 1.5

      const diceMeshRef = (ref: any) => {
        diceMeshRefs.current[idx] = ref
      }

      const diceMatRef = (ref: any) => {
        diceMatRefs.current[idx] = ref
      }

      const textRef = (ref: any) => {
        textRefs.current[idx] = ref
      }

      models.push(
        <group>
          <DiceModel
            key={idx}
            ref={{ diceMeshRef, diceMatRef } as any}
            meshProps={{ position: [xPos, yPos, zPos] }}
            boxGeometryProps={{ args: [0.33, 0.33, 0.33, 10, 10, 10] }}
          />
          <DiceNumber ref={textRef as any} position-y={0.7} fontSize={0.23} visible={false} />
        </group>
      )
    })

    return models
  }, [amount])

  useEffect(() => {
    if (multiDiceState === 'start') {
      animClock.current.start()
    }

    if (multiDiceState === 'resolve') {
      animClock.current.start()
      currentBatchIdx.current = 1
      selectedSide.current = Number(useDiceGameState.getState().submittedEntry?.side) / 100
      resultSides.current = useDiceGameState.getState().results?.resultSides || []
      resultRewards.current = useDiceGameState.getState().results?.rewards || []
      submittedAmount.current = useDiceGameState.getState().submittedEntry?.entryAmount || 0
      submittedCount.current = useDiceGameState.getState().submittedEntry?.entryCount || 0
      playedCount.current = useDiceGameState.getState().results?.playedCount || 0
    }
  }, [multiDiceState])

  const getNextBatchIdx = () => {
    const canGoNext = currentBatchIdx.current * 5 - diceMeshRefs.current.length < 0
    if (canGoNext) {
      currentBatchIdx.current++
      return true
    } else {
      return false
    }
  }

  const determineColor = (
    selectedSide: number,
    resolveSide: number,
    diceMatRef: MeshPhysicalMaterial,
    diceIdx: number
  ) => {
    if (diceMatRef.emissiveIntensity > 2) return

    const didWin = resolveSide > selectedSide
    const _color = didWin ? winColor : loseColor
    const _emission = didWin ? winEmissionColor : loseEmissionColor
    const _intensitiy = didWin ? 2.3 : 5.9

    diceMatRef.color = _color
    diceMatRef.emissive = _emission
    diceMatRef.emissiveIntensity = _intensitiy

    const textRef = textRefs.current[diceIdx]
    textRef.color = _emission
    ;(textRef.material as MeshPhysicalMaterial).emissive = _emission
    ;(textRef.material as MeshPhysicalMaterial).emissiveIntensity = _intensitiy
    textRef.visible = true
  }

  const displayRandomNum = (diceIdx: number, randomNum: number) => {
    const textRef = textRefs.current[diceIdx]
    const diceMesh = diceMeshRefs.current[diceIdx]

    textRef.visible = true
    textRef.text = numeral(String(randomNum)).format('00.00')
    ;(textRef.position as any).set(diceMesh.position.x, 0.6, diceMesh.position.z)
  }

  const zinkToTop = (elapsedTime: number) => {
    diceMeshRefs.current.forEach((diceMesh, idx) => {
      const delay = idx * ZINK_DELAY

      if (elapsedTime >= delay) {
        const t = (elapsedTime - delay) / ZINK_TO_TOP_TIME
        const shapingT = easeIn(t)
        const posY = lerp(DICE_START_Y, DICE_END_Y, shapingT)
        const rot = lerp(0, Math.PI * 2, shapingT)

        diceMesh.position.y = posY

        diceMesh.rotation.x = rot
        diceMesh.rotation.z = rot

        if (t >= 1 && diceMeshRefs.current.length - 1 === idx) animClock.current.stop()
      }
    })
  }

  const hidePreviousDice = () => {
    diceMeshRefs.current
      .slice((currentBatchIdx.current - 1) * 5, currentBatchIdx.current * 5)
      .forEach(diceRef => {
        diceRef.visible = false
      })

    diceMatRefs.current.forEach(diceMatRef => {
      diceMatRef.color = whiteColor
      diceMatRef.emissive = whiteColor
      diceMatRef.emissiveIntensity = 0
    })

    textRefs.current
      .slice((currentBatchIdx.current - 1) * 5, currentBatchIdx.current * 5)
      .forEach(textRef => {
        textRef.visible = false
        textRef.color = whiteColor
        ;(textRef.material as MeshPhysicalMaterial).emissive = whiteColor
        ;(textRef.material as MeshPhysicalMaterial).emissiveIntensity = 0
      })
  }

  const zonkToBottom = (elapsedTime: number) => {
    const diceRefs = diceMeshRefs.current.slice(
      (currentBatchIdx.current - 1) * 5,
      currentBatchIdx.current * 5
    )

    diceRefs.forEach((diceMesh, ogIdx) => {
      const idx = ogIdx + (currentBatchIdx.current - 1) * 5
      const delay = ogIdx * ZINK_DELAY
      let t = (elapsedTime - delay) / ZINK_TO_TOP_TIME

      if (elapsedTime >= delay && t <= 1) {
        const shapingT = easeOutSine(t)
        const posY = lerp(DICE_END_Y, DICE_START_Y, shapingT)
        const rotX = lerp(Math.PI * 6, 0, shapingT)
        const rotZ = lerp(Math.PI * 6, 0, shapingT)

        diceMesh.position.y = posY

        diceMesh.rotation.x = rotX
        diceMesh.rotation.z = rotZ
      }

      if (t >= 1) {
        t = 1
      }

      if (t === 1 && !isResolved.current[idx]) {
        const randomNum = Number(resultSides.current[idx]) / 100
        determineColor(selectedSide.current, randomNum, diceMatRefs.current[idx], idx)
        displayRandomNum(idx, randomNum)
        isResolved.current[idx] = true
        // @NOTE: Handles the delta amount text to render according to the stopLoss stopGain cases
        if (playedCount.current > idx) {
          entryEvent.pub('entryFinished', {
            deltaAmount:
              Number(resultRewards.current[idx]) - submittedAmount.current / submittedCount.current,
          })
        }
      }
    })

    const batchHasFinished =
      (elapsedTime + diceRefs.length * -ZINK_DELAY - NEXT_BATCH_DELAY) / ZINK_TO_TOP_TIME > 1

    if (batchHasFinished) {
      hidePreviousDice()
      const hasNext = getNextBatchIdx()
      animClock.current.stop()
      if (hasNext) {
        animClock.current.start()
      } else {
        resetDice()
        // if (playedCount < idx) {
        entryEvent.pub('gameFinished')
        entryEvent.pub('updateBalance')
        // }
      }
    }
  }

  useFrame(({ clock }) => {
    // if (multiDiceState === 'start' && animClock.current.running) {
    //   zinkToTop(animClock.current.getElapsedTime())
    // }

    if (multiDiceState === 'resolve' && animClock.current.running && currentBatchIdx.current > 0) {
      zonkToBottom(animClock.current.getElapsedTime())
    }
  })

  return <>{DiceModels}</>
}
