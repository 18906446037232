import { useIsBreakpoint } from '@/hooks/common/useIsBreakpoint'
import { ProfileWrapper } from './style'

interface IMobileProfileProps {
  isShowProfile: boolean
  onHide?: () => void
}

export const MobileProfile = ({ isShowProfile, onHide }: IMobileProfileProps) => {
  const isMobileScreen = useIsBreakpoint('sm')

  useEffect(() => {
    if (!isMobileScreen) {
      onHide?.()
    }
  }, [isMobileScreen, onHide])

  if (!isMobileScreen) return undefined

  return (
    <ProfileWrapper
      initial={{ opacity: 0 }}
      animate={{
        opacity: isShowProfile ? 1 : 0,
        pointerEvents: isShowProfile ? 'all' : 'none',
        zIndex: isShowProfile ? 100 : -1,
      }}
      transition={{ duration: 0.1 }}
    >
      <div>Profile</div>
    </ProfileWrapper>
  )
}
