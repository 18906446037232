import { BORDER_COLORS, FARE_COLORS } from '@/design'
import { addAppNoti } from '@/store/useNotiStore'
import { AnimatePresence, type Variants, motion } from 'framer-motion'

interface ClickToCopyProps {
  children: React.ReactNode
  className?: string
  copyText: string
}

const SClickToCopy = styled.div`
  flex: 1;
  all: unset;
  cursor: pointer;
  /* border: 1px solid ${BORDER_COLORS.one}; */
  padding: 0px;
  padding-left: 18px;
  padding-right: 18px;
  border-radius: 6px;
  /* background: #121212; */
  font-size: 14px;
  color: rgba(255, 255, 255, 0.8);
  height: 100%;
  transition: ease-in-out all 0.12s;
  border: 1px solid ${BORDER_COLORS.one};
  box-shadow: inset 0px 0px 56px ${FARE_COLORS.blue}00;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    border: 1px solid ${FARE_COLORS.blue};
    box-shadow: inset 0px 0px 56px ${FARE_COLORS.blue}75;
  }
`

export const ClickToCopyInviteCode = ({ children, copyText, ...props }: ClickToCopyProps) => {
  const copyToClipboard = useCallback(async () => {
    try {
      await navigator.clipboard.writeText(copyText)
      addAppNoti({
        type: 'success',
        msg: 'Copied to clipboard',
      })
    } catch (err) {
      console.error(err)
      addAppNoti({
        type: 'error',
        msg: 'Failed to copy',
      })
      throw new Error(`Error copying to clipboard inside ClickToCopyInviteCode`)
    }
  }, [copyText])

  const elem = useMemo(
    () => (typeof children === 'string' ? <span>{children}</span> : children),
    [children]
  )

  return (
    <SClickToCopy {...props} onClick={copyToClipboard}>
      {elem}
    </SClickToCopy>
  )
}
