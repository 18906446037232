import numeral from 'numeral'

import { SGameHistoryWrapper, SGameHistory, SGameResult, SGameResultWrapper } from './styled'
import useUserDataStore, { type IGameHistory } from '@/store/useUserDataStore'
import {
  USDC_DECIMALS,
  ZERO_ADDRESS,
  contractAddressToSVGMap,
  formatUsdc,
  gameNameToSVGMap,
  tokenAddressToFormatter,
} from '@/lib/crypto'
import { FarePopover } from '../shared/FarePopover'
import { COLORS } from '@/design'
import { SVGS } from '@/assets'
import { utils } from 'ethers'

export const GameHistory = () => {
  const { latestSUEntries } = useUserDataStore()
  const [hoveredGameTx, setHoveredGameTx] = useState('')

  const handleMouseEnter = useCallback((txHash: string) => setHoveredGameTx(txHash), [])
  const handleMouseLeave = useCallback(() => setHoveredGameTx(''), [])

  const gameHistoryElems = useMemo(
    () =>
      latestSUEntries.map((game: IGameHistory) => {
        const decimalizedDeltaAmount = utils.formatUnits(game.deltaAmount || 0, USDC_DECIMALS)
        const popoverTextColor =
          Number(decimalizedDeltaAmount) >= 0 ? COLORS.softSuccess : COLORS.softError
        const formattedDecimalizedDeltaAmount = numeral(decimalizedDeltaAmount)
          .format('0,0')
          .replace('-', '')
        const gameImageToRender = () => {
          if (game.gameName && (gameNameToSVGMap as any)[game.gameName as string]) {
            return (
              <img
                src={(gameNameToSVGMap as any)[game.gameName as string] || SVGS.questionMarkIcon}
                alt={'game icon'}
              />
            )
          }
          return <img src={SVGS.questionMarkIcon} alt={'game icon'} />
        }
        return (
          <SGameHistory
            key={game.resolvementTxHash}
            href={`https://sepolia.arbiscan.io/tx/${game.resolvementTxHash}`}
            target='_blank'
            onMouseEnter={() => handleMouseEnter(game.resolvementTxHash)}
            onMouseLeave={handleMouseLeave}
          >
            <FarePopover
              isShowing={hoveredGameTx === game.resolvementTxHash}
              textColor={popoverTextColor}
            >
              {formattedDecimalizedDeltaAmount}
            </FarePopover>
            {gameImageToRender()}
            <SGameResultWrapper>
              <SGameResult $isWin={Number(game?.deltaAmount) > 0} />
            </SGameResultWrapper>
          </SGameHistory>
        )
      }),
    [latestSUEntries, hoveredGameTx]
  )

  return <SGameHistoryWrapper>{gameHistoryElems}</SGameHistoryWrapper>
}
