import { DicePage } from './pages/DicePage'
import { CoinFlipPage } from './pages/CoinFlipPage'
import { RPSPage } from './pages/RPSPage'
import SettingsPage from './pages/SettingsPage'
import { PointsPage } from './pages/PointsPage'
import LinkTwitter from './components/shared/LinkTwitter'
import LinkDiscord from './components/shared/LinkDiscord'
import { LockoutPage } from './pages/LockoutPage'
// import PrivateRoute from './components/shared/PrivateRoute'
//
// @TODO: Need to handle all of the rendering of mobile components
// import ConnectWallet from './components/shared/Wallet/ConnectWallet'
// import { MobileChatPage } from './pages/MobileChatPage'
// import { DeathmatchPage } from './pages/DeathmatchPage'
// import { QuestsPage } from './pages/QuestsPage'
// import { MobileQuestsPage } from './pages/QuestsPage/MobileQuestsPage'
// import { MobileDeathmatchPage } from './pages/DeathmatchPage/MobileDeathmatchPage'
// import { LeaderboardPage } from './pages/LeaderboardPage'
// import { MobileLeaderboardPage } from './pages/LeaderboardPage/MobileLeaderboardPage'
// import { MobilePointsPage } from './pages/PointsPage/MobilePointsPage'
// import { MobileDicePage } from './pages/DicePage/MobileDicePage'
// import { MobileCoinFlipPage } from './pages/CoinFlipPage/MobileCoinFlipPage'
// import { MobileRPSPage } from './pages/RPSPage/MobileRPSPage'

export const PublicRoutes = () => {
  return (
    <Routes>
      <Route path='/connect-wallet' element={<LockoutPage />} />
    </Routes>
  )
}

export const PrivateRoutes = () => {
  return (
    <Routes>
      {/* <Route path='/' element={<PrivateRoute />}> */}
      <Route path='/'>
        <Route index element={<Navigate to='/deathmatch' replace />} />
        <Route path='/dice' element={<DicePage />} />
        <Route path='/coin-flip' element={<CoinFlipPage />} />
        <Route path='/rps' element={<RPSPage />} />
        <Route path='/settings' element={<SettingsPage />} />
        <Route path='/deathmatch' element={<PointsPage />} />
        <Route path='/link-twitter' element={<LinkTwitter />} />
        <Route path='/link-discord' element={<LinkDiscord />} />
        <Route path='*' element={<Navigate to='/deathmatch' replace />} />
      </Route>
    </Routes>
  )
}
