import useRandomnessType from '@/hooks/useRandomnessType'
import { useWeb3 } from '@/hooks/useWeb3'
import useZeroDev from '@/hooks/useZeroDev'
import { getRandomnessTypeFromLocalStorage } from '@/store/useRandomnessTypeStore'
import { getIsUsingQuickPlayFromLocalStorage } from '@/store/useZeroDevStore'
import { usePrevious } from 'react-use'
import useMaxValues from '@/hooks/useMaxValues'
import useMaxValuesStore, { getMaxValuesFromLocalStorage } from '@/store/useMaxValuesStore'

export const HandleLocalStorageWithAccountChange = () => {
  const { account, provider } = useWeb3()
  const prevAccount = usePrevious(account)
  const { setRandomnessType } = useRandomnessType()
  const { setIsUsingAA } = useZeroDev()
  const { fetchSCMaxValues } = useMaxValues()
  const { setMaxValues } = useMaxValuesStore()

  // @NOTE: Things to handle: randomnessType, isUsingQuickPlay and maybe authToken in future????

  useEffect(() => {
    // @NOTE: Run for both the initial connection and account changes
    if (account && prevAccount !== account) {
      // window.location.reload()
      const accountsRandomnessType = getRandomnessTypeFromLocalStorage(account)
      setRandomnessType(accountsRandomnessType, account)
      const accountsIsUsingQuickPlay = getIsUsingQuickPlayFromLocalStorage(account)
      setIsUsingAA(accountsIsUsingQuickPlay, account)
    }
    // @TODO: Probably do this with maxValues feature (if values do not exist, fetch sc values and have 10% buffer as default) save sc values and buffer applied values into local storage
    if (provider && account) {
      ;(async () => {
        console.log('account change: acc, prevAcc:', account, prevAccount)
        const scVals = await fetchSCMaxValues()
        const accountsMaxValues = getMaxValuesFromLocalStorage(account, scVals)
        console.log('account change: getMaxValues:', accountsMaxValues)
        setMaxValues(accountsMaxValues, account)
      })()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [account, provider, prevAccount, setRandomnessType, setIsUsingAA, fetchSCMaxValues])

  return <></>
}
