export const DEFAULT_CHAIN_ID = Number(import.meta.env.VITE_CHAIN_ID) || 51337
export const INFURA_API_KEY =
  import.meta.env.VITE_INFURA_API_KEY || '31aadaed31984e0e865701e3c96cb93b'
export const ALCHEMY_API_KEY =
  import.meta.env.VITE_ALCHEMY_API_KEY || 'L6XCbI7DTi5obnGVyNy85O07_ttEeChn'

export enum ConnectionType {
  INJECTED = 'INJECTED',
  COINBASE_WALLET = 'COINBASE_WALLET',
  WALLET_CONNECT = 'WALLET_CONNECT',
  NETWORK = 'NETWORK',
}

export enum SupportedChainId {
  MAINNET = 1,
  GOERLI = 5,
  ARBITRUM_ONE = 42161,
  ARBITRUM_GOERLI = 421613,
  ARBITRUM_SEPOLIA = 421614,
  LOCAL_HARDHAT = 31337,
  TEST_CHAIN = 31338,
}

export const CHAIN_IDS_TO_NAMES = {
  [SupportedChainId.MAINNET]: 'mainnet',
  [SupportedChainId.GOERLI]: 'goerli',
  [SupportedChainId.ARBITRUM_ONE]: 'arbitrum',
  [SupportedChainId.LOCAL_HARDHAT]: 'local_hardhat',
}

// TODO: Added testnet chain urls and infura/alchemy api key
export const CHAIN_URLS = {
  [SupportedChainId.MAINNET]: `https://mainnet.infura.io/v3/${INFURA_API_KEY}`,
  [SupportedChainId.LOCAL_HARDHAT]: 'http://localhost:8545',
  [SupportedChainId.TEST_CHAIN]: 'https://testnet.fareprotocol.io',
  [SupportedChainId.ARBITRUM_ONE]: 'https://arbitrum-mainnet.infura.io',
  [SupportedChainId.ARBITRUM_GOERLI]: 'https://arbitrum-goerli.publicnode.com',
  [SupportedChainId.ARBITRUM_SEPOLIA]: `https://arb-sepolia.g.alchemy.com/v2/${ALCHEMY_API_KEY}`,
  // [SupportedChainId.ARBITRUM_SEPOLIA]: 'https://sepolia-rollup.arbitrum.io/rpc',
}

// @TODO: Could just use value from .env
export const DEFAULT_CHAIN_URL = `https://arb-sepolia.g.alchemy.com/v2/${ALCHEMY_API_KEY}`
// export const DEFAULT_CHAIN_URL = `https://arbitrum-goerli.infura.io/v3/${INFURA_API_KEY}`

export const ALL_SUPPORTED_CHAIN_IDS: SupportedChainId[] = Object.values(SupportedChainId).filter(
  id => typeof id === 'number'
) as SupportedChainId[]

export function isSupportedChain(chainId: number | null | undefined): chainId is SupportedChainId {
  return !!chainId && !!SupportedChainId[chainId]
}
