import { OrbitControls, PerspectiveCamera } from '@react-three/drei'
import { Vector3, type PerspectiveCamera as PerspectiveCameraImpl, Clock } from 'three'
import { useControls } from 'leva'
import { useFrame, type PerspectiveCameraProps } from '@react-three/fiber'
import { signal } from '@preact/signals-react'
import { easeInOutCubic, quadratic } from '@/utils/3d'

export const camTargetSig = signal<'Idle' | 'Start' | 'Resolve' | 'Reset'>('Idle')

export const DiceCamera = (props: PerspectiveCameraProps) => {
  const camClock = useRef<Clock>(new Clock(false))
  const originPos = useRef<Vector3>(new Vector3(0, 2, 5.5))
  const targetPos = useRef<Vector3>()
  const duration = useRef<number>(2)
  const targetLookAtPos = useRef<Vector3>(new Vector3(0, 3, 0))
  const currentLookAtPos = useRef<Vector3>(new Vector3(0, 0, 0))
  const originLookAtPos = useRef<Vector3>(new Vector3())
  const cameraRef = useRef<PerspectiveCameraImpl>(null!)
  const vec3Zero = useMemo(() => new Vector3(0, 0, 0), [])
  const cameraPos = useMemo(() => new Vector3(0, 1.25, 5.25), [])
  const cameraVec3Start = useRef<Vector3>(new Vector3(0, 2, 5.5))
  // const { doesOrbit } = useControls('Scene', {
  //   doesOrbit: false,
  // })

  useEffect(() => {
    if (cameraRef.current) {
      cameraRef.current.lookAt(vec3Zero)
    }

    const unsub = camTargetSig.subscribe(value => {
      cameraVec3Start.current.copy(cameraRef.current.position)
      switch (value) {
        case 'Start':
          // originPos.current = cameraRef.current.position.clone()
          // targetPos.current = new Vector3(-6, 3, 0)
          // targetLookAtPos.current = new Vector3(0.5, 0.5, 0)
          // originLookAtPos.current.copy(currentLookAtPos.current)
          // duration.current = 1
          // camClock.current.start()
          break
        case 'Reset':
          // originPos.current = cameraRef.current.position.clone()
          // targetPos.current = cameraPos
          // targetLookAtPos.current = new Vector3(0, 0, 0)
          // originLookAtPos.current.copy(currentLookAtPos.current)
          // duration.current = 1
          // camClock.current.start()
          break
        default:
          break
      }
    })

    return () => unsub()
  }, [])

  useFrame(() => {
    if (targetPos.current && camClock.current.running && cameraRef.current) {
      const t = camClock.current.getElapsedTime() / duration.current

      cameraRef.current.position
        .copy(originPos.current)
        .lerp(targetPos.current, easeInOutCubic(t))
        .setY(cameraRef.current.position.y + quadratic(t, 1.2))
        .setX(cameraRef.current.position.x + quadratic(t, -1))

      if (camTargetSig.value === 'Start') {
        if (t >= 0.5) {
          currentLookAtPos.current
            .copy(originLookAtPos.current)
            .lerp(targetLookAtPos.current, (t - 0.5) / 0.5)
          cameraRef.current.lookAt(currentLookAtPos.current)
        } else {
          cameraRef.current.lookAt(vec3Zero)
        }
      } else {
        currentLookAtPos.current.copy(originLookAtPos.current).lerp(targetLookAtPos.current, t)
        cameraRef.current.lookAt(currentLookAtPos.current)
      }

      if (t >= 1) {
        camClock.current.stop()
        targetPos.current = undefined
        currentLookAtPos.current.copy(targetLookAtPos.current)
      }
    }
  })

  return (
    <>
      <PerspectiveCamera makeDefault ref={cameraRef} position={cameraPos} fov={60} {...props} />
      {/* {doesOrbit && <OrbitControls />} */}
    </>
  )
}
